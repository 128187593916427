import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, Outlet, useLocation, useNavigate, useResolvedPath} from "react-router-dom";
import { Navbar } from "@material-tailwind/react";
import NavItem from "components/nav-item";
import MaterialIcon from "components/material-icon";
import logo from './assets/img/logos/gobby-logo-FIN_Stacked-BL-wTag-.png';
import mouth from './assets/img/backgrounds/big_mouth.png';
import {authSelector, fetchSession} from "./store/auth";
import { toast } from "react-toastify";
import {
    Typography
} from '@material-tailwind/react';

function MyNavLink({to, icon, children}) {
    let location = useLocation();
    let path = useResolvedPath(to);

    let locationPathname = location.pathname.toLowerCase();
    let toPathname = path.pathname.toLowerCase();

    let isActive =
        locationPathname === toPathname ||
        (locationPathname.startsWith(toPathname) && locationPathname.charAt(toPathname.length) === "/");

    return (
        <Link to={to}>
            <NavItem ripple={"dark"} active={isActive ? "dark" : undefined}>
                {icon && <MaterialIcon name={icon} size="xl"/>}
                {children}
            </NavItem>
        </Link>
    );
}

export default function AdminWrapper() {
    const auth = useSelector(authSelector);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        // Redirect if logged out, else fetch session and see if logged in
        if (auth.status === 'loggedOut') {
            toast.warn('Login Session Expired');
            navigate('/login');
        } else if (auth.status !== 'pending') {
            dispatch(fetchSession());
        }
    }, [auth.status, dispatch, navigate]);

    return (
        <div className={"min-w-screen flex flex-col min-h-screen bg-gray-200"}>
            <Navbar fullWidth={true} className="sticky top-0 bg-black px-0 py-0 z-40 flex min-w-screen border-0">
                <div className="justify-startitems-center ml-1 my-1 basis-1/3">
                    <Link to={"/"}>
                        <img src={logo} alt={"Main Gobby site logo"} className={"sm:w-28 w-14 logo-image"}/>
                    </Link>
                </div>
                <div className="flex justify-end items-center sm:grow basis-2/3 sm:pr-6 pr-1 font-display">
                    <MyNavLink to={"/"} icon={"dashboard"}>Dashboard</MyNavLink>
                    <MyNavLink to={"/survey"} icon={"forum"}>Surveys</MyNavLink>
                    <MyNavLink to={"/profile"} icon={"account_box"}>Profile</MyNavLink>
                </div>
                <div className="flex justify-start items-center border-l-2 border-gray-400">    
                    <MyNavLink to={"/logout"} icon={"lock"}>
                        Logout
                    </MyNavLink>
                </div>
            </Navbar>
            {/* The actual rendering point for the application route! */}
            <div className={"justify-between bg-gray-200 items-top w-full flex-grow md:px-5 mb-28 md:py-5 px-2 z-0"}>
                <div className="w-full">
                    <Outlet/>
                </div>
            </div>
            <div className="fixed bottom-0 w-screen bg-[url('assets/img/backgrounds/footer_black.png')] flex flex-row">
                <div className="w-0 lg:w-[450px]">

                </div>
                <div className="grow h-28">
                    <Typography className="font-body text-center text-gray-100 mt-20">
                        &copy;
                        <a href="https://gobby.io/" target="_blank" rel="noreferrer"
                        className='visited:text-amber hover:text-orangesoda active:text-amber'>
                        Gobby&nbsp;Limited
                        </a>
                        &nbsp;2024
                    </Typography>
                </div>
                <div className="w-0 md:w-[450px] md:bg-[url('assets/img/backgrounds/big_mouth.png')]">
                </div>        
            </div>
        </div>
    );
}
