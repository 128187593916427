import React, {useEffect, useCallback, useState} from 'react';
import {useFormikContext} from 'formik';
import {Button, Dialog, DialogHeader, DialogBody, DialogFooter,
    Typography, Switch} from '@material-tailwind/react';
import FormikInput from '../form/formik-input';
import FormikTextarea from 'components/form/formik-textarea';

export default function QuestionSettingsDialog({showDialog, qI, handle}) {
    const {values, setFieldValue} = useFormikContext();
    const index = qI || 0;
    const [questionDescription, showQuestionDescription] = useState(false);
    const [questionDemographic, showQuestionDemographic] = useState(false);
    const [respondentsSeeOthers, showRespondentsSeeOthers] = useState(false);

    const updateStates = useCallback(() => {
        showQuestionDescription(values.questions?.[index]?.description ? true : false);
        showRespondentsSeeOthers(values.questions?.[index]?.is_view_all ? true : false);
        showQuestionDemographic(values.questions?.[index]?.is_demographic_question ? true : false);
    },[index, values.questions]);
    
    useEffect(() => {
        updateStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDialog]);

    const setQuestionType = (questionType) => {
        setFieldValue(`questions.${index}.question_type`, questionType);
    };

    const setAnswerType = (answerType) => {
        setFieldValue(`questions.${index}.answer_type`, answerType);
    };

    const handleCheckDescription = () => {
        if (questionDescription) {
            setFieldValue(`questions.${index}.description`, '');
            showQuestionDescription(false);
        } else {
            showQuestionDescription(true);
        }
    };

    const handleQuestionDemographic = () => {
        if (questionDemographic && values.questions?.[index]?.is_demographic_question) {
            setFieldValue(`questions.${index}.is_demographic_question`, false);
            showQuestionDemographic(false);
        } else {
            setFieldValue(`questions.${index}.is_demographic_question`, true);
            showQuestionDemographic(true);
        }
    };

    const handleCheckViewOthers = () => {
        if (respondentsSeeOthers && values.questions?.[index]?.is_view_all) {
            setFieldValue(`questions.${index}.is_view_all`, false);
            showRespondentsSeeOthers(false);
        } else {
            setFieldValue(`questions.${index}.is_view_all`, true);
            showRespondentsSeeOthers(true);
        }
    };


    return (

        <Dialog open={showDialog} handler={handle} className="rounded-xl overflow-visible min-w-fit" size="lg"         
        animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 1, y: -100 },
          }}>
            <DialogHeader className="g-gray-300 text-black font-display rounded-t-lg">
                Question Settings
            </DialogHeader>
            <DialogBody className='m-2 font-body overflow-y-auto max-h-[80vh]'>
                <div className="flex flex-col gap-2 flex-auto">
                    <div className="flex justify-between border border-gray-400 p-2 border-1 rounded-md">
                        <div className='basis-3/6'>
                            <div className="mr-2">
                                Question Type
                            </div>
                            <Typography variant="small" color="gray" className='font-body'>
                                Choose a Gobby&#8482; question to allow respondents to curate their own response; a pre-set question to provide fixed 
                                responses to select; a mixed question to allow both Gobby and pre-set responses. 
                            </Typography> 
                        </div>
                        
                        <div className="place-self-center text-center">
                            <Button color="blue-gray" type="button" className='hover:bg-amber-500 hover:text-black hover:border-amber-500 mt-1 rounded-full'
                                variant={`${values.questions?.[index]?.question_type === 'freeform' ? 'filled' : 'outlined'}`}
                                size="sm" onClick={() => {setQuestionType('freeform')}}>
                                Gobby&#8482;
                            </Button>
                            &ensp;
                            <Button color="blue-gray" type="button" className='hover:bg-amber-500 hover:text-black hover:border-amber-500 mt-1 rounded-full'
                                variant={`${values.questions?.[index]?.question_type === 'mixed' ? 'filled' : 'outlined'}`}
                                size="sm" onClick={() => {setQuestionType('mixed')}}>
                                Mixed
                            </Button>
                            &ensp;
                            <Button color="blue-gray" type="button" className='hover:bg-amber-500 hover:text-black hover:border-amber-500 mt-1 rounded-full'
                                variant={`${values.questions?.[index]?.question_type === 'fixed' ? 'filled' : 'outlined'}`}
                                size="sm" onClick={() => {setQuestionType('fixed')}}>
                                Pre-set
                            </Button>

                        </div>
                    </div>
                    {((values.questions?.[index]?.question_type === 'mixed' ||values.questions?.[index]?.question_type === 'freeform') &&
                        <div className="flex justify-between border border-gray-400 p-2 border-1 rounded-md">
                            <div>
                                <div className="mr-2">
                                    Go full Gobby&#8482; to allow respondents to see similar answers from other people
                                </div>
                                <Typography variant="small" color="gray" className='font-body'>
                                    Deactivate when respondents may need to share sensitive information privately
                                </Typography>
                            </div>
                            <div className="place-self-center">
                                <Switch checked={respondentsSeeOthers} color="blue-gray" id="checkbox3" text=""
                                    onChange={() => handleCheckViewOthers()} />
                            </div>
                        </div>
                    )}
                    <div className="flex justify-between border border-gray-400 p-2 border-1 rounded-md">
                        <div>
                            <div className="mr-2">
                                Number of permitted responses
                            </div>
                            <Typography variant="small" color="gray" className='font-body'>
                                How many responses any one contributor can provide
                            </Typography>
                        </div>
                        <div className="place-self-center text-center">
                            <Button color="blue-gray" type="button" className='hover:bg-amber-500 hover:text-black hover:border-amber-500 mt-1 rounded-full'
                                variant={`${values.questions?.[index]?.answer_type === 'single' ? 'filled' : 'outlined'}`}
                                size="sm" onClick={() => setAnswerType('single')}>
                                Single
                            </Button>
                            &ensp;
                            <Button color="blue-gray" type="button" className='hover:bg-amber-500 hover:text-black hover:border-amber-500 mt-1 rounded-full'
                            variant={`${values.questions?.[index]?.answer_type === 'multiple' ? 'filled' : 'outlined'}`}
                                size="sm" onClick={() => setAnswerType('multiple')}>
                                Multiple
                            </Button>

                        </div>
                    </div>
                    {((values.questions?.[index]?.question_type === 'mixed' ||values.questions?.[index]?.question_type === 'freeform') &&
                        <div className="flex justify-between border border-gray-400 p-2 border-1 rounded-md">
                            <div>
                                <div className="mr-2">
                                    Limit length of curated responses
                                </div>
                                <Typography variant="small" color="gray" className='font-body'>
                                    Maximum 200 Characters
                                </Typography>
                            </div>
                            <div className="place-self-center border rounded border-gray-500 text-center">
                                <FormikInput className="p-2 font-body text-center" type="number" variant="static" size="md" color="orange" min="1" max="200" 
                                    name={`questions.${index}.answer_limit`} placeholder="enter a value" />characters
                            </div>
                        </div>
                    )}
                    <div className="flex justify-between border border-gray-400 p-2 border-1 rounded-md">
                        <div>
                            <div className="mr-2">
                                Add a question description
                            </div>
                            <Typography variant="small" color="gray" className='font-body'>
                                Use if you need to add further information, or instruction, to a question
                            </Typography>
                        </div>
                        <div className="place-self-center">
                            <Switch checked={questionDescription} color="blue-gray" id="checkbox4" text=""
                                onChange={() => handleCheckDescription()} />
                        </div>
                    </div>
                    <div className="flex justify-between border border-gray-400 p-2 border-1 rounded-md">
                        <div className='basis-5/6'>
                            <div className="mr-2">
                                Track as a Demographic question
                            </div>
                            <Typography variant="small" color="gray" className='font-body'>
                                Use this toggle to track if the question data will be used for demographics in the
                                result data. Please ensure you have informed your respondents of this using the applicable data protection laws!
                            </Typography>
                        </div>
                        <div className="place-self-center">
                            <Switch checked={questionDemographic} color="blue-gray" id="checkbox5" text=""
                                onChange={() => handleQuestionDemographic()} />
                        </div>
                    </div>
                    {( questionDescription &&
                        <div className="flex justify-between border border-gray-400 p-2 border-1 rounded-ml">
                            <FormikTextarea name={`questions.${index}.description`} type="text" 
                            color="indigo" className="border border-gray-400 p-2 rounded-xl font-body" label='Question Description'/>
                        </div>
                    )}
                </div>
                <p></p>                             
            </DialogBody>
            
            <DialogFooter className='mt-6'>
                <div className='absolute bottom-4 right-4 '>
                    <Button type="button" variant="filled" color='white'
                    className="rounded-full py-1 px-6 font-body font-thin normal-case text-lg hover:border-1 hover:border-blue-500 hover:text-blue-500 border-gray-700 border" onClick={() => handle()}>
                        Apply
                    </Button>
                </div>
            </DialogFooter>
        </Dialog>
    )
}
