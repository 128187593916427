import React, {useState, createRef, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import useSWR, {mutate} from 'swr';
import {
    Button, Tabs, TabsHeader, TabsBody, Tab, TabPanel,
    Card, CardBody, CardFooter, Dialog, DialogHeader, DialogBody,
    DialogFooter, IconButton, Typography, Checkbox, Tooltip, Accordion, AccordionBody, AccordionHeader,
    CardHeader} from '@material-tailwind/react';
import {Form, Formik, FieldArray, useFormikContext} from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';
import { useBeforeunload } from 'react-beforeunload';
import {fetcherSimple, fetcherFull, fetcherFileUpload} from 'utils/api';
import {emptySurveyQuestionData} from 'utils/consts';
import FormikInput from 'components/form/formik-input';
import FormikTextarea from 'components/form/formik-textarea';
import DeleteQuestionDialog from 'components/dialog/delete-question-dialog';
import QuestionSettingsDialog from 'components/dialog/question-settings-dialog';
import QuestionAnswerInputs from 'components/form/question-answers';
import ContentWrapper from "components/ui/content-wrapper";
import MaterialIcon from 'components/material-icon';
import IconAcc from 'components/accordion-icon';
import Image from 'components/image';
import UploadPicture from 'assets/img/uploadimagepic.png';
import ProcessReportDialog from 'components/dialog/process-report-dialog';
import ConfirmReportAdminDialog from 'components/dialog/confirm-report-admin-dialog';
import ModerationTab from 'components/form/moderation-tab';
import ReportsTab from 'components/form/reports-tab';
import PeopleContent from 'components/form/people-content';
import ThemingTab from 'components/form/theming-tab';
import AnswerThemingDialog from 'components/dialog/answer-theming-dialog';
import ThemeAnswersDialog from 'components/dialog/theme-answers-dialog';
import RespondentResponseSentimentListDialog from 'components/dialog/respondent-response-sentiment-list';
import FormikSwitch from 'components/form/formik-switch';
import ResponsesSentimentListDialog from 'components/dialog/responses-sentiment-list';

export default function BuildSurvey() {
    dayjs.extend(utc);
    dayjs.extend(isSameOrBefore);
    dayjs.extend(duration);
    dayjs.extend(relativeTime);
    const {surveyId} = useParams();

    //AccordionControls
    const [open, setOpen] = React.useState(1);     
    const handleOpen = (value) => setOpen(open === value ? 0 : value);
                  
    // Data Pagination
    const [themesPage, setThemesPage] = useState(1);
    // Dialog controls
    const [showSettingsDialog, setShowSettingsDialog] = useState(false);
    const [showQuestionDeleteDialog, setShowQuestionDeleteDialog] = useState(false);
    const [showProcessDialog, setShowProcessDialog] = useState(false);
    const [showPublishDialog, setShowPublishDialog] = useState(false);
    const [showPauseDialog, setShowPauseDialog] = useState(false);
    const [showResumeDialog, setShowResumeDialog] = useState(false);
    const [showRestartDialog, setShowRestartDialog] = useState(false);
    const [showFinishDialog, setShowFinishDialog] = useState(false);
    const [showConfirmReportDialog, setShowConfirmReportDialog] = useState(false);
    const [showThemeDialog, setShowThemeDialog] = useState(false);
    const [showThemeDeleteDialog, setShowThemeDeleteDialog] = useState(false);
    const [showThemeAnswersDialog, setShowThemeAnswersDialog] = useState(false);
    const [showAnswerThemingDialog, setShowAnswerThemingDialog] = useState(false);
    const [showRespondentResponseSentimentListDialog, setShowRespondentResponseSentimentListDialog] = useState(false);
    const [showResponsesSentimentListDialog, setShowResponsesSentimentListDialog] = useState(false);
    // Editing variables
    const [confirmingAnswer, setConfirmingAnswer] = useState(false);
    const [processingReport, setProcessingReport] = useState(false);
    const [themeEdit, setThemeEdit] = useState(undefined);
    const [answerTheming, setAnswerTheming] = useState(undefined);
    const [questionEditIndex, setQuestionEditIndex] = useState(undefined);
    const [questionToDelete, setQuestionToDelete] = useState(undefined);
    const [questionsToDelete, setQuestionsToDelete] = useState([]);
    const [logoDeletable, setLogoDeletable] = useState(true);
    const [responseSentimentListId, setResponseSentimentListId] = useState(0);
    // survey state/view toggles
    const [instantActive, setInstantActive] = useState(false);
    const [moderationFilter, setModerationFilter] = useState("reports");
    const [themesState, setThemesState] = useState('edit');
    const [themeAnswersThemesState, setThemeAnswersThemesState] = useState('preset');
    const [modQShown, setModQShown] = useState(undefined);
    const [surveyDirty, setSurveyDirty] = useState(false);
    const [questionSentimentIndex, setQuestionSentimentIndex] = useState(0);
    const [keyphraseSentiment, setKeyphraseSentiment] = useState('');
    
    // Sentiment Data
    const [respondentSentimentData, setRespondentSentimentData] = useState();
    const [responsesSentimentData, setResponsesSentimentData] = useState();
    const [questionTotalSentimentData, setQuestionTotalSentimentData] = useState();
    const [responseSentimentIdList, setResponseSentimentIdList] = useState([]);
    // timestamp value function with dayjs
    const [timestamp, setTimestamp] = useState(dayjs());
    // Refs
    const inputLogoFileRef = createRef(null);

    // Data fetching and mutators
    const {
        data: survey,
        error: surveyError,
        mutate: surveyMutate
    } = useSWR([`/v2/survey/${surveyId}`, 'GET'], ([path, method]) => fetcherSimple(path, method));

    const {
        data: surveyQuestions,
        error: surveyQuestionsError,
        isValidating: surveyQuestionsValidating,
        mutate: surveyQuestionsMutate
    } = useSWR([`/v2/survey/${surveyId}/question`, 'GET'], ([path, method]) => fetcherSimple(path, method));

    const {
        data: surveyRecipients,
        error: surveyRecipientsError,
        mutate: recipientsMutate
    } = useSWR([`/v2/survey/${surveyId}/recipient/list`, 'GET'], ([path, method]) => fetcherSimple(path, method));

    const {
        data: surveyAbuse, 
        error: surveyAbuseError, 
        mutate: surveyAbuseMutate
    } = useSWR(() => [ (survey.state === 'finished' || survey.state === 'active' || survey.state === 'paused')
            ? `/v2/survey/${surveyId}/abuse_reports`
            : null, 'GET'], ([path, method]) => fetcherSimple(path, method)
    );

    const {
        data: surveyRespondentAnswers, 
        error: surveyRespondentAnswersError,
        isValidating: surveyRespondentAnswersValidating,
        mutate: surveyRespondentAnswersMutate
    } = useSWR(() => [ (survey.state === 'finished' || survey.state === 'active' || survey.state === 'paused')
            ? `/v2/survey/${surveyId}/respondent_answers`
            : null, 'GET'], ([path, method]) => fetcherSimple(path, method)
    );
    
    const {
        data: themesData, 
        error: themesDataError, 
        mutate: themesDataMutate
    } = useSWR([`/v2/survey/${surveyId}/theme?page=${themesPage}`, 'GET'], ([path, method, params]) => fetcherFull(path, method));

    const {
        data: answerThemes, 
        error: answerThemesError,
        isValidating: answerThemesValidating,
        mutate: answerThemesMutate
    } = useSWR(() => [ answerTheming
            ? `/v2/response/answer/${answerTheming}/themes`
            : null, 'GET'], ([path, method]) => fetcherSimple(path, method)
    );

    const {
        data: themeAnswers, 
        error: themeAnswersError,
        isValidating: themeAnswersValidating,
        mutate: themeAnswersMutate
    } = useSWR(() => [ themeEdit
            ? `/v2/survey/${surveyId}/theme/${themeEdit.id}`
            : null, 'GET'], ([path, method]) => fetcherSimple(path, method)
    );

    // Reports JSON endpoint
    const {
        data: rawSurveyReport,
        error: rawSurveyReportError,
        mutate: rawSurveyReportMutate
    } = useSWR([`/v2/survey/${surveyId}/report/raw`, 'GET'], ([path, method]) => fetcherSimple(path, method));
    const {
        data: themesSurveyReport,
        error: themesSurveyReportError,
        mutate: themesSurveyReportMutate
    } = useSWR([`/v2/survey/${surveyId}/report/themes`, 'GET'], ([path, method]) => fetcherSimple(path, method));
    
    // Sentiment Analysis 
    const {
        data: keyphrasesSentiment,
        error: keyphrasesSentimentError,
        mutate: keyphrasesSentimentMutate
    } = useSWR([(survey && survey.state === 'finished')
        ? `/v2/survey/${surveyId}/keyphrases`
        : null, 'GET'], ([path, method]) => fetcherSimple(path, method)
    );

    const {
        data: questionResponsesSentiment,
        error: questionResponsesSentimentError,
        mutate: questionResponsesSentimentMutate
    } = useSWR([(survey && survey.state === 'finished')
        ? `/v2/survey/${surveyId}/question/sentiment`
        : null, 'GET', 'GET'], ([path, method]) => fetcherSimple(path, method)
    );

    const {
        data: questionTotalSentiment,
        error: questionTotalSentimentError,
        mutate: questionTotalSentimentMutate
    } = useSWR([(survey && survey.state === 'finished')
        ? `/v2/survey/${surveyId}/questions/sentiment`
        : null, 'GET'], ([path, method]) => fetcherSimple(path, method)
    );

    const {
        data: respondentsResponsesSentiment,
        error: respondentsResponsesSentimentError,
        mutate: respondentsResponsesSentimentMutate
    } = useSWR([(survey && survey.state === 'finished')
        ? `/v2/survey/${surveyId}/respondents/sentiment`
        : null, 'GET', 'GET'], ([path, method]) => fetcherSimple(path, method)
    );

    const {
        data: keyphraseBySentiment,
        error: keyphraseBySentimentError,
        mutate: keyphraseBySentimentMutate
    } = useSWR([(survey && survey.state === 'finished')
        ? `/v2/survey/${surveyId}/keyphrase/by_sentiment`
        : null, 'GET'], ([path, method]) => fetcherSimple(path, method)
    );

    const {
        data: sentimentByKeyphrase,
        error: sentimentByKeyphraseError,
        mutate: sentimentByKeyphraseMutate
    } = useSWR([(survey && survey.state === 'finished')
        ? `/v2/survey/${surveyId}/sentiment/by_keyphrase`
        : null, 'GET'], ([path, method]) => fetcherSimple(path, method)
    );

    useEffect(() => {
        if (respondentsResponsesSentiment) {
            const respondentsSentiment = structuredClone(respondentsResponsesSentiment);
            const respondentsSentimentProcessed = respondentsSentiment.map(respondent => {
                let maxValue = 0;
                const values = Object.values(respondent.respondent_sentiment);
                maxValue = Math.max(0, ...values);
                respondent.respondent_sentiment.highest = maxValue;
                return respondent;
            });
            let responsesSentiment = [];
            respondentsSentiment.forEach(respondent => responsesSentiment.push(...respondent.responses_with_sentiment));
            if (respondentsSentimentProcessed) {
                setRespondentSentimentData(respondentsSentimentProcessed);
            }
            if (responsesSentiment) {
                setResponsesSentimentData(responsesSentiment);
            }
        }
    }, [respondentsResponsesSentiment]);

    useEffect(() => {
        if (questionTotalSentiment) {
            const qTotalSent = structuredClone(questionTotalSentiment);
            const questionTotalSentimentProcessed = qTotalSent.map(question => {
                let maxValue = 0;
                const values = Object.values(question.respondent_sentiment);
                maxValue = Math.max(0, ...values);
                question.respondent_sentiment.highest = maxValue;
                return question;
            });
            if (questionTotalSentimentProcessed) {
                setQuestionTotalSentimentData(questionTotalSentimentProcessed)
            }
        }
    }, [questionTotalSentiment]);

    const imageUrl = process.env.REACT_APP_API_SERVER + `/v2/survey/${surveyId}/logo`;

    const answerSchema = Yup.object({
        text: Yup.string().notRequired(),
        position: Yup.number().required(),
    });

    const answerLaunchSchema = Yup.object({
        text: Yup.string().required(),
        position: Yup.number().required(),
    });

    const QuestionType = Yup.object({
        answer_type: Yup.string().required(),
        description: Yup.string().notRequired(),
        is_allow_custom: Yup.boolean().required(),
        is_random_order: Yup.boolean().required(),
        is_view_all: Yup.boolean().required(),
        position: Yup.number().required(),
        question_type: Yup.string().required(),
        text: Yup.string().notRequired(),
        preset_answers: Yup.array().of(answerSchema),
        answer_limit: Yup.number().required(),
    });

    const QuestionLaunchType = Yup.object().shape({
        answer_type: Yup.string().required(),
        description: Yup.string().notRequired(),
        is_allow_custom: Yup.boolean().required(),
        is_random_order: Yup.boolean().required(),
        is_view_all: Yup.boolean().required(),
        position: Yup.number().required(),
        question_type: Yup.string().required(),
        text: Yup.string().required("Each Question needs text!"),
        preset_answers: Yup.array().of(answerLaunchSchema),
        answer_limit: Yup.number().min(1, 'Question Position must be at least 1').max(200)
    });

    const validationSchemaSurvey = Yup.object({
        name: Yup.string().required('Survey Name Required'),
        description: Yup.string().notRequired(),
        start_date: Yup.string().notRequired().test('Start Datetime', 'Invalid Start Time',
            (value, testContext) => {
                if (!dayjs(value).isValid()) {
                    return testContext.createError({message: 'Please enter a valid Start Time!'});
                } else if (dayjs(value).isBefore(dayjs(), 'day')) {
                    return testContext.createError({message: 'Start Time cannot be in the Past!'});
                } else {
                    return true;
                }
            }),
        end_date: Yup.string().notRequired().test('End Datetime', 'Invalid End Time',
        (value, testContext) => {
            if (!dayjs(value).isValid()) {
                return testContext.createError({message: 'Please enter a valid End Time!'});
            } else if (dayjs(value).isBefore(dayjs(), 'day')) {
                return testContext.createError({message: 'End Time cannot be in the Past!'});
            } else {
                return true;
            }
        }),
        start_text: Yup.string().notRequired(),
        end_text: Yup.string().notRequired(),
        questions: Yup.array().of(QuestionType),
    });

    const launchValidationSchemaSurvey = Yup.object({
        name: Yup.string().required('Survey Name Required'),
        description: Yup.string(),
        start_date: Yup.string().notRequired().test('Start Datetime', 'Invalid Start Time',
        (value, testContext) => {
            if (!dayjs(value).isValid()) {
                return testContext.createError({message: 'Please enter a valid Start Time!'});
            } else if (dayjs(value).isBefore(dayjs(), 'day')) {
                return testContext.createError({message: 'Start Time cannot be in the Past!'});
            } else {
                return true;
            }
        }),
        is_allow_anonymous: Yup.boolean().test('Participants', 'No Valid Participants',
        (value, testContext) => {
            if (!value && (surveyRecipients.length < 1))  {
                return testContext.createError({message: 'Either allow Anonymous Participants or Add Them!'});
            } else {
                return true;
            }
        }),
        end_date: Yup.string().required().test('End Datetime', 'Invalid End Time',
        (value, testContext) => {
            if (!dayjs(value).isValid()) {
                return testContext.createError({message: 'Please enter a valid End Time!'});
            } else if (dayjs(value).isBefore(dayjs(), 'day')) {
                return testContext.createError({message: 'End Time cannot be in the Past!'});
            } else {
                return true;
            }
        }),
        start_text: Yup.string().required('Start Text Required'),
        end_text: Yup.string().required('End Text Required'),
        questions: Yup.array(QuestionLaunchType).min(1),
    });

    const validationSchemaTheme = Yup.object({
        name: Yup.string().required('Theme Name Required'),
        description: Yup.string().notRequired(),
    });

    const validationSchemaAnswerThemes = Yup.object({
        themes: Yup.array()
    });

    const validationSchemaThemeAnswers = Yup.object({
        theme: Yup.string(),
        answers_preset: Yup.array(),
        answers_custom: Yup.array()
    });

    const FormObserver = () => {
        const {dirty} = useFormikContext();
        useEffect(() => {
            if (dirty) {
                setSurveyDirty(true);
            } else {
                setSurveyDirty(false);
            }
          }, [dirty]);
        
        return null;
    }

    useBeforeunload(surveyDirty === true ? (event) => event.preventDefault() : null);

    const handleAddQuestion = (values, pushCallback) => {
        const newQuestion = emptySurveyQuestionData;
        newQuestion.position = values.questions.at(-1)?.position + 1 || surveyQuestions.length + 1;
        pushCallback(emptySurveyQuestionData);
    };

    const handleCloneQuestion = (index, question, insertCallback) => {
        const clonedQuestion = cloneDeep(question);
        delete clonedQuestion.id;
        delete clonedQuestion.nanoid;
        clonedQuestion.position = question.position + 1;
        surveyQuestions.forEach((q) => {
            if(q.position > question.position) {
                q.position += 1;
            }
        });
        clonedQuestion.preset_answers?.forEach((pa) => {
            delete pa.id;
            delete pa.nanoid;
            delete pa.question_id;
        });
        insertCallback(index + 1, clonedQuestion);
    };

    const setQuestionChop = (index) => {
        setQuestionToDelete(index);
        setShowQuestionDeleteDialog(true);
    };

    const moveQuestionUp = (swap, values, index, setFieldValue) => {
        const oldPos = values.questions[index].position;
        setFieldValue(`questions.${index}.position`, values.questions[index-1].position);
        setFieldValue(`questions.${index-1}.position`, oldPos);
        swap(index, index-1);
    };

    const moveQuestionDown = (swap, values, index, setFieldValue) => {
        const oldPos = values.questions[index].position;
        setFieldValue(`questions.${index}.position`, values.questions[index+1].position);
        setFieldValue(`questions.${index+1}.position`, oldPos);
        swap(index, index+1);
    };

    const previousThemesPage = async () => {
        const previousPage = themesPage;
        setThemesPage(previousPage - 1);
        themesDataMutate();
    }

    const nextThemesPage = async () => {
        const previousPage = themesPage;
        setThemesPage(previousPage + 1);
        themesDataMutate();
    }

    const checkLaunch = async (values) => {
        if (survey.state !== 'draft' && survey.state !== 'paused') {
            toast.warn('Survey has already been started and can no longer be edited! Pause survey for more functions.');
            return;
        } else if (!instantActive && !dayjs(values.start_date).isValid()) {
            toast.error('Please ensure the survey has a valid start date');
            return;
        };
        try {
            await launchValidationSchemaSurvey.validate(values);
            toast.success('Survey is ready for Launch!');
        } catch (err) {
            err.errors.map(errorString => toast.error(`${errorString}`));
            return;
        }
    }

    const launchSurveySubmit = async (values) => {
        if (survey.state !== 'draft' && survey.state !== 'published' && survey.state !== 'paused') {
            toast.warn('Survey has already been started and can no longer be edited! Pause survey for more functions.');
            return;
        } else if (!instantActive && !dayjs(values.start_date).isValid()) {
            toast.error('Please ensure the survey has a valid start date');
            return;
        };
        try {
            await launchValidationSchemaSurvey.validate(values);
        } catch (err) {
            err.errors.map(errorString => toast.error(`${errorString}`));
            return;
        }
        await handleSurveySubmit(values);
        setShowPublishDialog(true);
    }

    const handlePublishDialogSubmit = async () => {
        setShowPublishDialog(false);
        const response = await fetcherSimple((instantActive ? `/v2/survey/${surveyId}/activate` : `/v2/survey/${surveyId}/publish`), 'GET');
        if (response) {
            if (response.name) window.location.reload();
        }
    };

    const handlePauseDialogSubmit = async () => {
        setShowPauseDialog(false);
        const response = await fetcherSimple(`/v2/survey/${surveyId}/pause`, 'GET');
        if (response) {
            if (response.name) window.location.reload();
        }
    };

    const handleResumeDialogSubmit = async () => {
        setShowResumeDialog(false);
        const response = await fetcherSimple(`/v2/survey/${surveyId}/resume`, 'POST');
        if (response) {
            if (response.name) window.location.reload();
        }
    };

    const handleRestartDialogSubmit = async () => {
        setShowRestartDialog(false);
        const response = await fetcherSimple(`/v2/survey/${surveyId}/reopen`, 'POST');
        if (response) {
            if (response.name) window.location.reload();
        }
    };

    const handleFinishDialogSubmit = async () => {
        setShowFinishDialog(false);
        const response = await fetcherSimple(`/v2/survey/${surveyId}/finish`, 'GET');
        if (response) {
            if (response.name) window.location.reload();
        }
    };

    const handlePositioningAfterDelete = (questionValues) => {
        let questionValuesPositioned = questionValues.forEach((question, index) => {
            if ((index === 0) && !(questionValues.map(q => q.position).find(e => e === 1))) {
                question.position = 1;
            }
            if (question.position !== 1) {
                const closestPrevQuestionPos = questionValues.map(q => q.position).reverse().find(e => e < question.position);
                console.log(closestPrevQuestionPos);
                question.position = closestPrevQuestionPos + 1;
            }
        });
        return questionValuesPositioned;
    }

    const handleThemeEditSubmit = async (values) => {
        // If there's an ID, we're editing an existing Theme
        if (themeEdit?.id) {
            const themeObj = {
                name: values.name,
                description: values.description
            }
            let response = undefined;
            try {
                response = await fetcherFull(`/v2/survey/${surveyId}/theme/${themeEdit.id}`, 'PUT', themeObj);
            } catch {
                const serverError = response || 'Unknown issue editing Theme.';
                toast.error(`Error editing Theme, ${serverError}.`);
            }
            if (response && response?.id) {
                themesDataMutate();
                setThemeEdit(undefined);
                toast.success('Theme Edited');
            } else {
                const serverError = response || 'Unknown issue editing Theme.';
                toast.error(`Error editing Theme, ${serverError}`);
                console.log(response.errors);
            }
        } else {
            const themeObj = {
                name: values.name,
                description: values.description
            }
            let response = undefined;
            try {
                response = await fetcherFull(`/v2/survey/${surveyId}/theme`, 'POST', themeObj);
            } catch {
                const serverError = response || 'Unknown issue editing Theme.';
                toast.error(`Error editing Theme, ${serverError}.`);
            }
            if (response && response?.name) {
                themesDataMutate();
                rawSurveyReportMutate();
                themesSurveyReportMutate();
                setThemeEdit(undefined);
                toast.success('Theme Created');
            } else {
                const serverError = response || 'Unknown issue editing Theme.';
                toast.error(`Error creating Theme, ${serverError}`);
                console.log(response);
            }
        }
    };

    const handleDeleteThemeSubmit = async (themeId) => {
        setShowThemeDeleteDialog(false);
        let response = undefined;
        try {
            response = await fetcherSimple(`/v2/survey/${surveyId}/theme/${themeId}`, 'DELETE');
        } catch {
            const serverError = response || 'Unknown issue deleting Theme.';
            toast.error(`Error deleting Theme, ${serverError}.`);
        }
        if (response && response?.deleted === true) {
            toast.success('Theme Deleted');
        } else {
            const serverError = response || 'unknown issue in deleting Theme.';
            toast.error(`Error deleting Theme, ${serverError}`);
            console.log(response.errors);
        }
        if (response) {
            themesDataMutate();
            rawSurveyReportMutate();
            themesSurveyReportMutate();
            setThemeEdit(undefined);
        }
    }

    const handleAnswerThemingSubmit = async (values) => {
        const response = await fetcherFull(`/v2/response/answer/${answerTheming}/themes`, 'POST', values);
        if (response) {
            if (response?.themes) {
                themesDataMutate();
                answerThemesMutate();
                themeAnswersMutate();
                rawSurveyReportMutate();
                themesSurveyReportMutate();
            }
        }
    };

    const handleThemeAnswersSubmit = async (values) => {
        if (themeEdit) {
            let response = undefined;
            const themeObj = {
                preset: values.answers_preset,
                custom: values.answers_custom
            }
            try {
                response = await fetcherFull(`/v2/survey/${surveyId}/theme/${themeEdit.id}`, 'POST', themeObj);
            } catch {
                const serverError = response || 'Unknown issue editing Theme.';
                toast.error(`Error editing Theme, ${serverError}.`);
            }
            if (response.id) {
                themesDataMutate();
                answerThemesMutate();
                themeAnswersMutate();
                rawSurveyReportMutate();
                themesSurveyReportMutate();
                setModQShown(undefined);
                setThemeEdit(undefined);
                toast.success('Theme Edited');
            } else {
                const serverError = response || 'Unknown issue editing Theme.';
                toast.error(`Error deleting Theme, ${serverError}`);
                console.log(response.errors);
            }
        }
    };

    const handleLogoUploadClick = (values) => {
        handleSurveySubmit(values);
        inputLogoFileRef.current?.click();
    };

    const logoUploadHandler = async (event) => {
        const csvSubmitToast = toast.loading('Saving Image...');
        if (!event.target.files) {
            toast.error('No file uploaded!');
            return;
        }
        const selectedFile = event.target.files[0];
        if (!selectedFile.type.includes('image/')) {
            toast.error('Only Image Files are supported. Please ensure that the file you are loading is not excessively large and has the correct extension and name.');
            return;
        }
        let imageResponse = undefined;
        try {
            imageResponse = await fetcherFileUpload(
                `/v2/survey/${surveyId}/logo`, 'POST', 'logo', selectedFile
            );
        } catch {
            const serverError = imageResponse || 'unknown issue in uploading image. Please try a different size or type.';
            toast.update(csvSubmitToast, {render: `Error uploading Image, ${serverError}.`, type: 'error', isLoading: false, autoClose: 5000, 
            pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true});
        }
        if (imageResponse && imageResponse?.status === 200) {
            toast.update(csvSubmitToast, {render: 'Logo Image Uploaded', type: 'success', isLoading: false, autoClose: 5000, 
            pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true});
            setTimestamp(dayjs());
            setLogoDeletable(true);
        } else {
            const serverError = imageResponse || 'unknown issue in uploading image. Please try a different size or type.';
            toast.update(csvSubmitToast, {render: `Error uploading Image, ${serverError}`, type: 'error', isLoading: false, autoClose: 5000, 
            pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true});
            console.log(imageResponse.errors);
        }
    }

    const handleDeleteLogo = async (values) => {
        await handleSurveySubmit(values);
        const deleteResponse = await fetcherSimple(
            `/v2/survey/${surveyId}/logo`, 'DELETE'
        );
        if (deleteResponse && deleteResponse.deleted) {
            toast.success('Logo Deleted!');
            setLogoDeletable(false);
            setTimestamp(dayjs());
        } else if (deleteResponse && deleteResponse.error) {
            toast.error(`Error deleting logo: ${deleteResponse.message}`);
        } else {
            toast.error('Error deleting logo!')
        }
    } 

    const handleSettingsOpen = (i) => {
        setQuestionEditIndex(i);
        setShowSettingsDialog(true);
    };

    const handleSurveySubmit = async (values) => {
        if (survey.state !== 'draft' && survey.state !== 'paused') {
            toast.warn('Survey has already been started and can no longer be edited! You can pause the survey for more features.');
            return;
        };
        const questionValues = values.questions;
        if (dayjs(values.end_date).isValid()) values.end_date = dayjs(values.end_date).utc().format('YYYY-MM-DDTHH:mm');
        if (instantActive) values.start_date = dayjs().utc().format('YYYY-MM-DDTHH:mm');
        if (!instantActive && dayjs(values.start_date).isValid()) {
            values.start_date = dayjs(values.start_date).utc().format('YYYY-MM-DDTHH:mm');
        }
        if (!instantActive && dayjs(values.start_date).isValid() && 
        dayjs(values.end_date).isValid() &&
        dayjs(values.end_date).isBefore(dayjs(values.start_date)) ) {
            toast.error("Start Date cannot be after the End Date!");
            return;
        }
        if (!values.survey_website) {
            delete values.survey_website;
        }
        if (!values.policy_url) {
            delete values.policy_url;
        }
        if (survey.state === 'paused') {
            delete values.start_date;
        }
        // Handle survey info
        const surveySubmitToast = toast.loading('Saving Survey...');
        const response = await fetcherFull(`/v2/survey/${surveyId}`, 'PUT', values).catch((errorText) => {
            return {error: errorText}
        });
        if (response?.name) {
            if (response) {
                toast.update(surveySubmitToast, {render: 'Survey Saved!', type: 'success', isLoading: false, autoClose: 5000, 
                    pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true});
            }
            let surveyQuestionDeleteErrors = [];
            let questionValuedPositioned = [];
            await Promise.all(questionsToDelete.map(async (questionId, index) => {
                let path = `/v2/survey/${surveyId}/question/${questionId}`;
                fetcherSimple(path, 'DELETE');
                return questionId;
            })).then(() => {
                setQuestionsToDelete([]);
                mutate(`/v2/survey/${surveyId}/question`);
            }).catch((error) => {
                console.log(error);
                surveyQuestionDeleteErrors.push(error);
                if (surveyQuestionDeleteErrors) {
                    surveyQuestionDeleteErrors.map((error) => {
                        toast.error(`There has been an error deleting the question! ${error}.`);
                        return error;
                    });
                }
            });
            // handle questions
            const questionValuedPositionedNew = handlePositioningAfterDelete(questionValues);
            questionValuedPositioned = questionValuedPositionedNew || questionValues;
            await Promise.all(questionValuedPositioned.map(async (question, index) => {
                let method = 'PUT';
                let path = `/v2/survey/${surveyId}/question`;
                if (question.nanoid === undefined) {
                    method = 'POST';
                } else {
                    path += `/${question.id}`;
                }
                try {
                    await fetcherFull(path, method, question);
                } catch (error) {
                    toast.error(`There has been an error saving the question! ${question.position ? question.position + ': ' : ''} ${error}`);
                }
            }));
            surveyMutate();
            surveyQuestionsMutate();
            themesDataMutate();
        } else {
            if (response?.error) {
                toast.update(surveySubmitToast, {render: `There has been an error saving the survey, ${response.error}.`, type: 'error', isLoading: false, 
                    autoClose: 5000, pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true});
            } else {
                toast.update(surveySubmitToast, {render: `There has been an error saving the survey.`, type: 'error', isLoading: false, 
                autoClose: 5000, pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true});
            }
        }
    }

    const previewSurvey = async (values) => {
        if (survey.state !== 'draft') {
            toast.warn('Survey has already been started and can no longer be edited! Please pause the survey for more options.');
            return;
        };
        await handleSurveySubmit(values);
        window.open(`/respond/${survey.nanoid}`, "_blank");
    }

    return (
        <>
            {((surveyError || surveyQuestionsError) &&
                <Typography>
                    Error loading survey!
                </Typography>
            )}
            {(survey && surveyQuestions &&
                <Formik initialValues={{
                    name: survey.name || '',
                    description: survey.description || '',
                    start_text: survey.start_text || '',
                    end_text: survey.end_text || '',
                    survey_website: survey.survey_website || '',
                    policy_url: survey.policy_url || '',
                    use_comprehend: survey.use_comprehend || false,
                    is_allow_anonymous: survey.is_allow_anonymous || false,
                    start_date: survey.start_date ? dayjs(survey.start_date).format('YYYY-MM-DDTHH:mm') : '',
                    end_date: survey.end_date ? dayjs(survey.end_date).format('YYYY-MM-DDTHH:mm') : '',
                    questions: (surveyQuestions.length ? surveyQuestions : [emptySurveyQuestionData])
                }}
                    onSubmit={handleSurveySubmit}
                    validationSchema={validationSchemaSurvey}
                    enableReinitialize
                >
                {({values, isValid, dirty, setFieldValue}) => (
                    <Form>
                        <FormObserver/>
                        <ContentWrapper> 
                            <div className="flex flex-wrap justify-end py-2 rounded-xl pl-4 pr-2 bg-blue-gray-500 2xl:mx-[10%] xl:mx-[8%] lg:mx[6%]">
                                <div className='text-white font-display text-center sm:text-left flex-grow flex-nowrap text-xl mb-2'>
                                    <span className='text-amber-500 font-display sm:text-lg text-sm'>Survey:</span>&nbsp;<span className="sm:text-lg text-sm">{survey.name}</span>
                                </div>
                                <div className="w-full lg:w-8/12 gap-2 md:order-2 md:self-center flex place-self-center sm:justify-end justify-center md:mt-0">
                                    <Button className="rounded-full px-4 py-2 text-sm font-body font-thin normal-case hover:border-1 hover:border-blue-500 hover:text-blue-500 mr-1" 
                                        type="submit" 
                                        color="white"
                                        variant="filled" 
                                        disabled={!dirty} 
                                        >
                                        Save
                                    </Button>
                                    {((survey.state === 'draft' || survey.state === 'published' || survey.state === 'paused') &&
                                        <>
                                        <Button className="rounded-full px-4 py-2 text-sm font-body font-thin normal-case hover:border-1 hover:border-blue-500 hover:text-blue-500 mr-1" 
                                            type="button" 
                                            color="white"
                                            variant="filled" 
                                            onClick={() => checkLaunch(values)} 
                                            disabled={!((survey.state === 'draft' || survey.state === 'published' || survey.state === 'paused'))} 
                                            >
                                            Pre-Launch Check
                                        </Button>
                                        {((survey.state === 'draft' || survey.state === 'published') &&
                                        <>
                                        <Button className="rounded-full px-4 py-2 text-sm font-body font-thin normal-case hover:border-1 hover:border-blue-500 hover:text-blue-500 mr-1" 
                                            type="button"
                                            disabled={!((survey.state === 'draft' || survey.state === 'published')) || !(isValid)} 
                                            onClick={() => previewSurvey(values)} 
                                            variant="filled" 
                                            color="white" 
                                            >
                                            Preview Survey
                                        </Button>
                                        <Button className="rounded-full px-4 py-2 text-sm font-body font-thin normal-case hover:border-1 hover:border-blue-500 hover:text-blue-500 mr-1" 
                                            type="button" 
                                            color="white"
                                            variant="filled" 
                                            onClick={() => launchSurveySubmit(values)} 
                                            disabled={!((survey.state === 'draft' || survey.state === 'published'))} 
                                            >
                                            Launch Survey
                                        </Button>
                                        </>
                                        )}
                                        </>
                                    )}
                                    {((survey.state === 'published' || survey.state === 'active') &&
                                        <Button 
                                            type="button" 
                                            color="white" 
                                            variant="filled" 
                                            size="lg" 
                                            className="rounded-full px-4 py-2 text-sm font-body font-thin normal-case hover:border-1 hover:border-blue-500 hover:text-blue-500 mr-1" 
                                            onClick={() => navigator.clipboard.writeText(window.location.origin + `/respond/${survey.nanoid}`)}>
                                            Copy URL
                                        </Button>
                                    )}
                                    {((survey.state === 'active') &&
                                        <>
                                        <Button className="rounded-full px-4 py-2 text-sm font-body font-thin normal-case hover:border-1 hover:border-blue-500 hover:text-blue-500 mr-1" 
                                            type="button" 
                                            color={isValid ? "white" : "blue-gray"} 
                                            variant="filled" 
                                            onClick={() => setShowPauseDialog(true)} 
                                            disabled={!(survey.state === 'active')} 
                                            size="lg">
                                            Pause Survey
                                        </Button>
                                        <Button className="rounded-full px-4 py-2 text-sm font-body font-thin normal-case hover:border-1 hover:border-blue-500 hover:text-blue-500 mr-1" 
                                            type="button" 
                                            color={isValid ? "white" : "blue-gray"} 
                                            variant="filled" 
                                            onClick={() => setShowFinishDialog(true)} 
                                            disabled={!(survey.state === 'active')} 
                                            size="lg">
                                            Finish Survey
                                        </Button>
                                        </>
                                    )}
                                    {((survey.state === 'paused') &&
                                        <>
                                        <Button className="rounded-full px-4 py-2 text-sm font-body font-thin normal-case hover:border-1 hover:border-blue-500 hover:text-blue-500 mr-1" 
                                            type="button" 
                                            color={isValid ? "white" : "gray"} 
                                            variant="filled" 
                                            onClick={() => setShowResumeDialog(true)} 
                                            disabled={!(survey.state === 'paused')} 
                                            size="lg">
                                            Resume Survey
                                        </Button>
                                        </>
                                    )}
                                    {((survey.state === 'finished') &&
                                        <Button className="rounded-full px-4 py-2 text-sm font-body font-thin normal-case hover:border-1 hover:border-blue-500 hover:text-blue-500 mr-1" 
                                            type="button" 
                                            color={isValid ? "white" : "blue-gray"} 
                                            variant="filled" 
                                            onClick={() => setShowRestartDialog(true)} 
                                            size="lg">
                                            Restart Survey
                                        </Button>
                                    )}
                                </div>
                            </div>
                            <div className="mb-0 py-0 mt-2  2xl:mx-[10%] xl:mx-[8%] lg:mx[6%] mx-auto">
                                <div className="flex pt-4 flex-wrap justify-center">
                                    <div className="w-full flex flex-col">
                                        <Tabs value="settings">
                                            <TabsHeader className="sm:px-4 py-0 bg-transparent">
                                                <Tab className="sm:mx-2 sm:py-2 px-1 font-body sm:font-display text-sm md:text-lg hover:text-black hover:underline border-2 border-gray-400 mb-2 bg-blue-gray-100 text-blue-gray-700 rounded-lg" 
                                                value="settings" >Settings</Tab>
                                                {((survey.state === 'draft' || survey.state === 'published') &&
                                                <Tab className="sm:mx-2 sm:py-2 px-1 font-body sm:font-display text-sm md:text-lg hover:text-black hover:underline border-2 border-gray-400 mb-2 bg-blue-gray-100 text-blue-gray-700 rounded-lg"  
                                                value="questions">Questions</Tab>
                                                )}
                                                {((survey.state === 'draft'  || survey.state === 'published' || survey.state === 'active') &&
                                                <>
                                                </>
                                                )}
                                                {((survey.state === 'active' || survey.state === 'finished' || survey.state === 'paused') &&
                                                <Tab className="sm:mx-2 sm:py-2 px-1 font-body sm:font-display text-sm md:text-lg hover:text-black hover:underline border-2 border-gray-400 mb-2 bg-blue-gray-100 text-blue-gray-700 rounded-lg"  
                                                value="moderation">Moderation</Tab>
                                                )}
                                                <Tab className="sm:mx-2 sm:py-2 px-1 font-body sm:font-display text-sm md:text-lg hover:text-black hover:underline border-2 border-gray-400 mb-2 bg-blue-gray-100 text-blue-gray-700 rounded-lg"  
                                                value="theming">Theme Manager</Tab>
                                                {((survey.state === 'finished' || survey.state === 'active' || survey.state === 'paused') &&
                                                <>
                                                <Tab className="sm:mx-2 sm:py-2 px-1 font-body sm:font-display text-sm md:text-lg hover:text-black hover:underline border-2 border-gray-400 mb-2 bg-blue-gray-100 text-blue-gray-700 rounded-lg"  
                                                value="reports">Reports</Tab>
                                                </>
                                                )}
                                            </TabsHeader>
                                            <TabsBody>
                                                <TabPanel value="settings" className=''>
                                                    <Accordion open={open === 1} icon={<IconAcc id={1} open={open} />} >
                                                        <AccordionHeader className="bg-white px-8 py-4 text-2xl rounded-full hover:border-b-blue-300 border border-blue-gray-100 border-b-4 
                        border-b-blue-gray-200 hover:bg-gray-300 md:mt-4 mt-2" onClick={() => handleOpen(1)}>
                                                            Survey Details
                                                        </AccordionHeader>
                                                        <AccordionBody className="bg-white mt-1 py-1 rounded-lg border-b-2 border-gray-500 px-2 mt-4">
                                                            <div className="w-full flex flex-row gap-4 pt-2 items-centre">
                                                                <div className="flex-auto p-2 mx-auto font-body lg:mx-[2%]">
                                                                    <div>
                                                                        <FormikInput 
                                                                        name="name"
                                                                        color="teal"
                                                                        variant="outlined" 
                                                                        type="text" 
                                                                        className="px-6 text-blue-gray-600 text-lg font-display" 
                                                                        label="Survey Name"
                                                                        disabled={!((survey.state === 'draft' || survey.state === 'published'))}
                                                                        />
                                                                    </div>
                                                                    <div className="pt-6">
                                                                        <FormikTextarea
                                                                        variant="outlined"
                                                                        color="teal" 
                                                                        name="description"
                                                                        className="pt-6 text-gray-900 text-lg font-body bg-gray-100" 
                                                                        label="Description (for personal notes, not shown to respondent)"/>
                                                                    </div>
                                                                    {((survey.state === 'draft' || survey.state === 'published') &&
                                                                    <>
                                                                    <div className="pt-4">
                                                                        <FormikTextarea
                                                                        variant="outlined"
                                                                        color="teal" 
                                                                        name="start_text"
                                                                        className="pt-6 text-gray-900 text-lg font-body bg-gray-100"
                                                                        label="Landing Page Text"
                                                                        placeholder="Please enter some text that your respondents will see on entering the survey."
                                                                        />
                                                                    </div>
                                                                    <div className="pt-4">
                                                                        <FormikTextarea
                                                                        variant="outlined"
                                                                        color="teal" 
                                                                        name="end_text"
                                                                        className="pt-6 text-gray-900 text-lg font-body bg-gray-100"
                                                                        label="Thank You Text"
                                                                        placeholder="Please enter some text that your respondents will see on finishing the survey."
                                                                        />
                                                                    </div>
                                                                    <div className="pt-4">
                                                                        <FormikInput 
                                                                        name="survey_website"
                                                                        variant="outlined" 
                                                                        color="teal"
                                                                        type="text" 
                                                                        className="pt-8 pb-4 text-gray-900 text-lg font-body bg-gray-100" 
                                                                        label="Link to Survey Owners Website. Note: please use full (absolute) url (e.g. http://www.myaddress.co.uk)"
                                                                        />
                                                                    </div>
                                                                    <div className="pt-8">
                                                                        <FormikInput 
                                                                        name="policy_url"
                                                                        variant="outlined" 
                                                                        color="teal"
                                                                        type="text" 
                                                                        className="pt-8 pb-4 text-gray-900 text-lg font-body bg-gray-100" 
                                                                        label="Link to Customer policies. Note: please use full (absolute) url (e.g. http://www.myaddress.co.uk)"
                                                                        />
                                                                    </div>
                                                                    <div className="mt-6 mb-6 
                                                                                    md:flex md:flex-row md:items-center bg-gray-200
                                                                                    md:border border-gray-700 rounded-xl
                                                                                    ">
                                                                        <div className='md:p-4 p-2'>
                                                                            <Button variant="gradient" type="button" color="white"
                                                                                onClick={() => handleLogoUploadClick(values)}
                                                                                className='rounded-full px-6 py-2 mr-2 
                                                                                mt-1 border border-gray-500
                                                                                hover:border-1 hover:border-blue-500 hover:text-blue-500 
                                                                                font-body normal-case font-thin' size="lg">
                                                                            Upload Logo
                                                                            </Button>
                                                                                {( logoDeletable &&
                                                                            <Button variant="gradient" type="button"
                                                                                onClick={() => handleDeleteLogo(values)}
                                                                                color="white"
                                                                                className='rounded-full px-6 py-2 mr-2 
                                                                                mt-1 border border-gray-500
                                                                                hover:border-1 hover:border-blue-500 hover:text-blue-500 
                                                                                font-body normal-case font-thin' size="lg">
                                                                                Delete Uploaded Logo
                                                                            </Button>
                                                                            )}
                                                                            <input onChange={logoUploadHandler}
                                                                                ref={inputLogoFileRef}
                                                                                type="file"
                                                                                accept="image/*"
                                                                                style={{ display: 'none' }}
                                                                                />
                                                                        </div>
                                                                        <div className='md:border-l md:border-gray-700 md:p-4 p-2 bg-gray-200'>
                                                                            <Image alt="survey logo" 
                                                                                key={timestamp}
                                                                                src={imageUrl}
                                                                                className="mt-6 rounded-lg border border-black"
                                                                                onError={({ currentTarget }) => {
                                                                                    currentTarget.onerror = null; // prevents looping
                                                                                    currentTarget.src=`${UploadPicture}`;
                                                                                    setLogoDeletable(false)
                                                                                }} 
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    </>
                                                                    )}
                                                                    {((survey.state === 'draft' || survey.state === 'published' || survey.state === 'paused') &&
                                                                    <>
                                                                    <Card shadow className='mt-2'>
                                                                    <CardHeader
                                                                        floated={false}
                                                                        shadow={true}
                                                                        className="w-full bg-blue-gray-500 text-white 
                                                                        m-0 rounded-t-xl rounded-b-none p-2 text-xl font-display flex justify-center">
                                                                        Enable AWS Comprehend on Survey Completion
                                                                    </CardHeader>
                                                                    <CardBody className="">
                                                                        <Typography as="span" className="mb-2 font-body text-blue-gray-700">
                                                                            <FormikSwitch name="use_comprehend" className='bg-blue-gray-500' color="green" />
                                                                             &nbsp;&nbsp;&nbsp;Send survey results to Amazon Comprehend for machine analysis.
                                                                        </Typography>
                                                                        <Typography as="span" className="font-body text-blue-gray-700">
                                                                            Enables AWS Comprehend for later reporting with 
                                                                             Sentiment Analysis, once the survey has
                                                                            been completed. Will also allow Amazon Comprehend to generate lists of keywords and keyphrases to aid with manual theming.
                                                                        </Typography>
                                                                    </CardBody>
                                                                    </Card>
                                                                    </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div>
                                                            </div>
                                                        </AccordionBody>
                                                    </Accordion>

                                                    <Accordion open={open === 2} icon={<IconAcc id={2} open={open} />} >
                                                        <AccordionHeader className="bg-white px-8 py-4 text-2xl rounded-full hover:border-b-blue-300 border border-blue-gray-100 border-b-4 
                        border-b-blue-gray-200 hover:bg-gray-300 md:mt-4 mt-2" 
                                                        onClick={() => handleOpen(2)}>
                                                            Manage Recipients
                                                        </AccordionHeader>
                                                        <AccordionBody className="bg-white mt-1 py-1 rounded-lg border-b-2 border-gray-500 px-2 mt-4">
                                                            <div>
                                                                <PeopleContent survey={survey} surveyId={surveyId}
                                                                    surveyRecipients={surveyRecipients} recipientsMutate={recipientsMutate}
                                                                    surveyRecipientsError={surveyRecipientsError}/>
                                                            </div>
                                                        </AccordionBody>
                                                    </Accordion>

                                                    <Accordion open={open === 3} icon={<IconAcc id={3} open={open} />} >
                                                        <AccordionHeader className="bg-white px-8 py-4 text-2xl rounded-full hover:border-b-blue-300 border border-blue-gray-100 border-b-4 
                        border-b-blue-gray-200 hover:bg-gray-300 md:mt-4 mt-2" onClick={() => handleOpen(3)}>
                                                            Survey Time & Date
                                                        </AccordionHeader>
                                                        <AccordionBody className="bg-white mt-1 py-1 rounded-lg border-b-2 border-gray-500 px-2 mt-4">
                                                            <div className='lg:max-w-[75%] mx-auto p-2'>
                                                                {((survey.state === 'draft' || survey.state === 'published' || survey.state === 'paused') &&
                                                                <div className='font-body flex flex-row-reverse mb-4 mr-2'>
                                                                    <div className='border border-gray-700 pr-4 rounded-xl bg-gray-200 text-black'>
                                                                        <Checkbox 
                                                                        checked={instantActive} 
                                                                        label="Start Survey Now"
                                                                        className='bg-white text-red-500' 
                                                                        onChange={()=>{
                                                                            if (instantActive) {
                                                                                setInstantActive(false);
                                                                            } else {
                                                                                setFieldValue('start_date', dayjs().format('YYYY-MM-DDTHH:mm'));
                                                                                setInstantActive(true);
                                                                            }
                                                                        }}/>
                                                                    </div>
                                                                </div>
                                                                )}
                                                                <div className={"sm:flex flex-row gap-6 font-body"}>
                                                                    {((!instantActive && (survey.state === 'draft' || survey.state === 'published' || survey.state === 'paused')) &&
                                                                    <div className='py-4 flex-grow'>
                                                                        <FormikInput className='font-display text-lg' name="start_date" type={"datetime-local"} label={"Enter Start Time"}/>
                                                                    </div>
                                                                    )}
                                                                    <div className='py-4 flex-grow'>
                                                                        <FormikInput className='font-display text-lg' name="end_date" type={"datetime-local"} label={"End Time"}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </AccordionBody>
                                                    </Accordion>
                                                </TabPanel>
                                                {((survey.state === 'draft' || survey.state === 'published') &&
                                                <TabPanel value="questions" className=''>
                                                    <FieldArray name="questions">
                                                        {({push, remove, insert, swap}) => (
                                                            <>
                                                                {values.questions.sort((a, b) => {return a.position - b.position;}).map((question, index) => (
                                                                    <Card key={index} className="mx-2 my-4 bg-white border rounded-xl border-gray-500">
                                                                        <CardBody>
                                                                            <div className="flex flex-col gap-2">
                                                                                <div className="flex justify-between gap-2">
                                                                                    <div className="text-sm bg-gray-200 mb-1 min-w-fit font-bold text-blue-gray-700 border border-gray-500 p-2 rounded-full">
                                                                                        Q.{index + 1}
                                                                                    </div>
                                                                                    <div className='text-sm md:text-lg text-black text-blue-gray-700'>
                                                                                        Type: <span className='inline text-sm text-blue-900 border rounded-full border-blue-gray-500 py-1 px-2 bg-gray-100'>{ question.question_type === 'mixed' ? 'Mixed' : (question.question_type === 'freeform' ? 'Gobby®' : 'Preset Answers')}</span>
                                                                                    </div>
                                                                                    <div>
                                                                                    <Tooltip
                                                                                        className="font-body" 
                                                                                        content="Change the question settings."
                                                                                        animate={{
                                                                                            mount: { scale: 1, y: 0 },
                                                                                            unmount: { scale: 1, y: 25 },
                                                                                        }}>
                                                                                        <IconButton color="green"
                                                                                                type="button"
                                                                                                variant="text"
                                                                                                onClick={() => handleSettingsOpen(index)}>
                                                                                            <MaterialIcon
                                                                                                name="settings"
                                                                                                size="2xl"/>
                                                                                        </IconButton>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <FormikInput
                                                                                        name={`questions.${index}.text`}
                                                                                        type="text"
                                                                                        color="black"
                                                                                        label="Please enter your question."
                                                                                        className="bg-gray-100 font-body"
                                                                                        />
                                                                                </div>
                                                                                <FieldArray
                                                                                    name={`questions.${index}.preset_answers`}>
                                                                                    {(answerArrayHelpers) => (
                                                                                        <QuestionAnswerInputs
                                                                                            questionIndex={index}
                                                                                            pushCallback={answerArrayHelpers.push}
                                                                                            removeCallback={answerArrayHelpers.remove}
                                                                                            swapCallback={answerArrayHelpers.swap}
                                                                                        />
                                                                                    )}
                                                                                </FieldArray>
                                                                            </div>
                                                                        </CardBody>
                                                                        <CardFooter className='p-0'>
                                                                            <div className="flex justify-end">
                                                                                <Tooltip 
                                                                                className="font-body" 
                                                                                content="Move this question up one position"
                                                                                animate={{
                                                                                    mount: { scale: 1, y: 0 },
                                                                                    unmount: { scale: 1, y: 25 },
                                                                                }}>
                                                                                    <IconButton color="blue-gray"
                                                                                                type="button"
                                                                                                variant="text"
                                                                                                disabled={index === 0}
                                                                                                onClick={() => moveQuestionUp(swap, values, index, setFieldValue)}>
                                                                                        <MaterialIcon name="arrow_upward"
                                                                                            size="xl"/>
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Tooltip 
                                                                                className="font-body" 
                                                                                content="Move this question down one position"
                                                                                animate={{
                                                                                    mount: { scale: 1, y: 0 },
                                                                                    unmount: { scale: 1, y: 25 },
                                                                                }}>
                                                                                    <IconButton color="blue-gray"
                                                                                                type="button"
                                                                                                variant="text"
                                                                                                disabled={index === values.questions.length-1}
                                                                                                onClick={() => moveQuestionDown(swap, values, index, setFieldValue)}>
                                                                                        <MaterialIcon name="arrow_downward"
                                                                                            size="xl"/>
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Tooltip  
                                                                                content="Duplicate the question."
                                                                                className="font-body" 
                                                                                animate={{
                                                                                    mount: { scale: 1, y: 0 },
                                                                                    unmount: { scale: 1, y: 25 },
                                                                                }}>
                                                                                    <IconButton color="blue"
                                                                                            type="button"
                                                                                            variant="text"
                                                                                            onClick={() => handleCloneQuestion(index, question, insert)}>
                                                                                        <MaterialIcon name="content_copy"
                                                                                            size="xl"/>
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Tooltip 
                                                                                className="font-body" 
                                                                                content="Delete the question dialogue."
                                                                                animate={{
                                                                                    mount: { scale: 1, y: 0 },
                                                                                    unmount: { scale: 1, y: 25 },
                                                                                }}>
                                                                                    <IconButton color="red"
                                                                                            type="button"
                                                                                            variant="text"                                                             
                                                                                            onClick={() => setQuestionChop(index)}>
                                                                                        <MaterialIcon name="delete"
                                                                                            size="xl"/>
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </div>
                                                                        </CardFooter>
                                                                    </Card>
                                                                ))}
                                                                <div className="flex justify-center">
                                                                    <Button
                                                                        className="
                                                                            rounded-full 
                                                                            text-lg 
                                                                            mt-2
                                                                            mb-6  
                                                                            px-6 
                                                                            py-2
                                                                            hover:bg-black
                                                                            hover:text-white
                                                                            border
                                                                            border-gray-500
                                                                            font-body 
                                                                            font-thin 
                                                                            normal-case"
                                                                        type="button"
                                                                        onClick={() => handleAddQuestion(values, push)}
                                                                        variant="gradient"
                                                                        sixe="lg"
                                                                        color="white"
                                                                        >
                                                                        Add Another Question
                                                                    </Button>
                                                                </div>
                                                                <DeleteQuestionDialog
                                                                    showDialog={showQuestionDeleteDialog}
                                                                    deleteIndex={questionToDelete}
                                                                    removeCallback={remove}
                                                                    setDeleteArrayState={setQuestionsToDelete}
                                                                    onClose={() => setQuestionToDelete(undefined)}
                                                                    handle={() => setShowQuestionDeleteDialog(showQuestionDeleteDialog=>!showQuestionDeleteDialog)}/>
                                                                <QuestionSettingsDialog
                                                                    showDialog={showSettingsDialog}
                                                                    qI={questionEditIndex}
                                                                    handle={() => setShowSettingsDialog(showSettingsDialog=>!showSettingsDialog)}/>
                                                            </>
                                                        )}
                                                    </FieldArray>
                                                </TabPanel>
                                                )}
                                                <ThemingTab tabValue="theming" setShowThemeDialog={setShowThemeDialog}
                                                    setShowAnswerThemingDialog={setShowAnswerThemingDialog}
                                                    setShowThemeAnswersDialog={setShowThemeAnswersDialog}
                                                    setAnswerTheming={setAnswerTheming} const={processingReport}
                                                    themesData={themesData} themesDataMutate={themesDataMutate}
                                                    themesDataError={themesDataError} themesState={themesState}
                                                    setThemesState={setThemesState} surveyRespondentAnswers={surveyRespondentAnswers}
                                                    surveyRespondentAnswersError={surveyRespondentAnswersError}
                                                    modQShown={modQShown} setModQShown={setModQShown}
                                                    surveyQuestions={surveyQuestions} setThemeEdit={setThemeEdit} 
                                                    setShowThemeDeleteDialog={setShowThemeDeleteDialog}
                                                    themesSurveyReport={themesSurveyReport} themesPage={themesPage} 
                                                    setThemesPage={setThemesPage} previousThemesPage={previousThemesPage}
                                                    nextThemesPage={nextThemesPage} themeAnswersMutate={themeAnswersMutate}/>
                                                {((survey.state === 'finished' ||survey.state === 'active' || survey.state === 'paused') &&
                                                <>
                                                <ModerationTab tabValue="moderation" setShowProcessDialog={setShowProcessDialog}
                                                    setProcessingReport={setProcessingReport} const={processingReport}
                                                    surveyAbuse={surveyAbuse} surveyAbuseMutate={surveyAbuseMutate}
                                                    surveyAbuseError={surveyAbuseError} moderationFilter={moderationFilter}
                                                    setModerationFilter={setModerationFilter} surveyRespondentAnswers={surveyRespondentAnswers}
                                                    surveyRespondentAnswersError={surveyRespondentAnswersError}
                                                    modQShown={modQShown} setModQShown={setModQShown}
                                                    setConfirmingAnswer={setConfirmingAnswer}
                                                    setShowConfirmReportDialog={setShowConfirmReportDialog}/>
                                                <ReportsTab tabValue="reports" survey={survey} surveyId={surveyId}
                                                    rawSurveyReport={rawSurveyReport} themesSurveyReport={themesSurveyReport}
                                                    questionResponsesSentiment={questionResponsesSentiment}
                                                    keyphrasesSentiment={keyphrasesSentiment} respondentsResponsesSentiment={respondentsResponsesSentiment}
                                                    setShowRespondentResponseSentimentListDialog={setShowRespondentResponseSentimentListDialog}
                                                    setResponseSentimentListId={setResponseSentimentListId} respondentSentimentData={respondentSentimentData}
                                                    keyphraseBySentiment={keyphraseBySentiment} sentimentByKeyphrase={sentimentByKeyphrase}
                                                    questionTotalSentimentData={questionTotalSentimentData} setThemeEdit={setThemeEdit}
                                                    setShowThemeDialog={setShowThemeDialog} questionSentimentIndex={questionSentimentIndex}
                                                    setQuestionSentimentIndex={setQuestionSentimentIndex} keyphraseSentiment={keyphraseSentiment}
                                                    setKeyphraseSentiment={setKeyphraseSentiment} 
                                                    setShowResponsesSentimentListDialog={setShowResponsesSentimentListDialog}
                                                    setResponseSentimentIdList={setResponseSentimentIdList}/>
                                                </>
                                                )}
                                            </TabsBody>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </ContentWrapper>
                    </Form>
                )}
                </Formik>
            )}
            <Dialog open={showPublishDialog} handler={() => setShowPublishDialog(showPublishDialog=>!showPublishDialog)}>
                <DialogHeader className="bg-gray-300 text-black font-display rounded-t-lg">
                    Publish Survey
                </DialogHeader>
                <DialogBody className="mb-7 font-body overflow-y-auto max-h-[80vh]">
                    Once the survey has started and is live, you can no longer save it. Are you sure?
                </DialogBody>
                <DialogFooter className="mb-2">
                    <div className="absolute bottom-4 right-4">
                        <Button type="button"
                                variant="gradient"
                                className="
                                bg-black
                                rounded-full 
                                text-lg mt-6 mr-4
                                px-6 py-2
                                hover:bg-amber-500
                                hover:text-black
                                font-body font-thin normal-case"
                                size="lg"   
                            onClick={() => setShowPublishDialog(false)}>       
                            Cancel
                        </Button>
                        <Button type="button"
                                        className="
                                        bg-green-700
                                        rounded-full 
                                        text-lg mt-6 
                                        px-6 py-2
                                        hover:bg-amber-500
                                        hover:text-black
                                        font-body font-thin normal-case"                         
                                color="black"
                                variant="gradient"  
                                onClick={() => handlePublishDialogSubmit()}>
                            Publish
                        </Button>
                    </div>
                </DialogFooter>
            </Dialog>
            <Dialog open={showPauseDialog} handler={() => setShowPauseDialog(showPauseDialog=>!showPauseDialog)}>
                <DialogHeader className="bg-blue-gray-500 text-white font-display rounded-t-lg">
                    Pause Survey
                </DialogHeader>
                <DialogBody divider className="mb-7 font-body overflow-y-auto max-h-[80vh]">
                    This will manually set the survey to "Paused" before its time expires.
                    Do you wish to proceed?
                </DialogBody>
                <DialogFooter className="mb-2">
                    <div className="absolute bottom-2 right-2">
                        <Button type="button"
                                variant="filled"
                                color="white"
                                size="lg"
                                className="mb-2 rounded-full 
                                py-1 px-4 font-body 
                                font-thin text-lg 
                                normal-case 
                                hover:border-1 
                                hover:border-red-500 
                                hover:text-white 
                                hover:bg-red-500
                                border border-gray-700 
                                mr-4 mt-6"
                            onClick={() => setShowPauseDialog(false)}>       
                            Cancel
                        </Button>
                        <Button type="button"
                                variant="filled"
                                color="white"
                                size="lg"
                                className="mb-2 rounded-full py-1 px-4 
                                font-body font-thin text-lg 
                                normal-case hover:border-1 
                                hover:border-blue-500 hover:text-blue-500 
                                border border-gray-700 mr-4 mt-6"
                                onClick={() => handlePauseDialogSubmit()}>
                            Pause
                        </Button>
                    </div>
                </DialogFooter>
            </Dialog>
            <Dialog open={showResumeDialog} handler={() => setShowResumeDialog(showResumeDialog=>!showResumeDialog)}>
                <DialogHeader className="bg-blue-gray-500 text-white font-display rounded-t-lg">
                    Resume Survey
                </DialogHeader>
                <DialogBody divider className="mb-7 font-body overflow-y-auto max-h-[80vh]">
                    This will resume the survey from a paused state before its time expires.
                    Do you wish to proceed?
                </DialogBody>
                <DialogFooter className="mb-2">
                    <div className="absolute bottom-4 right-4">
                        <Button type="button" 
                        variant="filled"
                                color="white"
                                size="lg"
                                className="mb-2 rounded-full 
                                py-1 px-4 font-body 
                                font-thin text-lg 
                                normal-case 
                                hover:border-1 
                                hover:border-red-500 
                                hover:text-white 
                                hover:bg-red-500
                                border border-gray-700 
                                mr-4 mt-6" 
                            onClick={() => setShowResumeDialog(false)}>       
                            Cancel
                        </Button>
                        <Button type="button"
                                        variant="filled"
                                        color="white"
                                        size="lg"
                                        className="mb-2 rounded-full py-1 px-4 
                                        font-body font-thin text-lg 
                                        normal-case hover:border-1 
                                        hover:border-blue-500 hover:text-blue-500 
                                        border border-gray-700 mr-4 mt-6"
                                onClick={() => handleResumeDialogSubmit()}>
                            Resume
                        </Button>
                    </div>
                </DialogFooter>
            </Dialog>
            <Dialog open={showRestartDialog} handler={() => setShowRestartDialog(showRestartDialog=>!showRestartDialog)}>
                <DialogHeader className="bg-gray-300 text-black font-display rounded-t-lg">
                    Restart Survey
                </DialogHeader>
                <DialogBody divider className="mb-7 font-body overflow-y-auto max-h-[80vh]">
                    This will restart the survey and set it to "Published".
                    Do you wish to proceed?
                </DialogBody>
                <DialogFooter className="mb-2">
                    <div className="absolute bottom-4 right-4">
                        <Button type="button"
                                className="
                                bg-black rounded-full 
                                text-lg mt-6 mr-4
                                px-6 py-2
                                hover:text-white hover:bg-amber-500
                                font-body font-thin normal-case"  
                                variant="gradient" 
                            onClick={() => setShowRestartDialog(false)}>       
                            Cancel
                        </Button>
                        <Button type="button"
                                        className="
                                        bg-green-700 rounded-full 
                                        text-lg mt-6 
                                        px-6 py-2
                                        hover:text-white hover:bg-amber-500
                                        font-body font-thin normal-case"                         
                                color="black"
                                variant="gradient"
                                onClick={() => handleRestartDialogSubmit()}>
                            Restart
                        </Button>
                    </div>
                </DialogFooter>
            </Dialog>
            <Dialog open={showFinishDialog} handler={() => setShowFinishDialog(showFinishDialog=>!showFinishDialog)}>
                <DialogHeader className="bg-blue-gray-500 text-white font-display rounded-t-lg">
                    Finish Survey
                </DialogHeader>
                <DialogBody className="mb-7 font-body overflow-y-auto max-h-[80vh]">
                    This will manually set the survey to "Finished" before its time expires, making it impossible to 
                    have any more responses submitted to it.
                    Do you wish to proceed?
                </DialogBody>
                <DialogFooter className="mb-2">
                    <div className="absolute bottom-4 right-4">
                        <Button type="button"
                                variant="filled"
                                color="white"
                                size="lg"
                                className="mb-2 rounded-full 
                                py-1 px-4 font-body 
                                font-thin text-lg 
                                normal-case 
                                hover:border-1 
                                hover:border-red-500 
                                hover:text-white 
                                hover:bg-red-500
                                border border-gray-700 
                                mr-4 mt-6" 
                            onClick={() => setShowFinishDialog(false)}>       
                            Cancel
                        </Button>
                        <Button type="button"
                                variant="filled"
                                color="white"
                                size="lg"
                                className="mb-2 rounded-full py-1 px-4 
                                font-body font-thin text-lg 
                                normal-case hover:border-1 
                                hover:border-blue-500 hover:text-blue-500 
                                border border-gray-700 mr-4 mt-6"
                                onClick={() => handleFinishDialogSubmit()}>
                            Finish
                        </Button>
                    </div>
                </DialogFooter>
            </Dialog>
            <Dialog open={showThemeDeleteDialog} handler={() => setShowThemeDeleteDialog(showThemeDeleteDialog=>!showThemeDeleteDialog)}>
                <DialogHeader className="bg-gray-300 text-black font-display rounded-t-lg">
                    Delete Theme
                </DialogHeader>
                <DialogBody className="mb-7 font-body overflow-y-auto max-h-[80vh]">
                    This will delete the Theme, are you sure?
                </DialogBody>
                <DialogFooter className="mb-2">
                    <div className="absolute bottom-4 right-4">
                        <Button type="button"
                                color="white"
                                className="
                                rounded-full
                                border
                                border-gray-600 
                                text-lg mt-6 
                                px-6 py-2 mr-4
                                hover:bg-black hover:text-white
                                font-body font-thin normal-case"  
                                variant="gradient" 
                                onClick={() => {
                                    setThemeEdit(undefined);
                                    setShowThemeDeleteDialog(false);
                                }}>
                            Cancel
                        </Button>
                        <Button type="button"
                                color="white"
                                className="
                                rounded-full 
                                border
                                border-gray-600
                                text-lg mt-6 
                                px-6 py-2 mr-4
                                hover:bg-red-700 hover:text-white
                                font-body font-thin normal-case"  
                                variant="gradient" 
                                onClick={() => {
                                    handleDeleteThemeSubmit(themeEdit.id);
                                    setShowThemeDeleteDialog(false);
                                }}>
                            Delete
                        </Button>
                    </div>
                </DialogFooter>
            </Dialog>
            <Dialog open={showThemeDialog} handler={() => setShowThemeDialog(showThemeDialog=>!showThemeDialog)}>
                <DialogHeader className="bg-gray-300 text-black font-display rounded-t-lg">
                    Create/Edit Theme
                </DialogHeader>
                <Formik initialValues={{
                        name: themeEdit?.name || '',
                        description: themeEdit?.description || '',
                    }} onSubmit={handleThemeEditSubmit} validationSchema={validationSchemaTheme}>
                    {({isValid, dirty}) => (
                        <Form>
                            <DialogBody className='font-body overflow-y-auto max-h-[80vh]'>
                                <div className="mb-6 px-2 bg-bb font-body">
                                    <FormikInput className='font-body' name="name" type="text" color="indigo"
                                        label="Theme Name" required/>
                                </div>
                                <div className="px-2 mb-6">
                                    <FormikTextarea className='font-body' name="description" type="text" color="indigo"
                                        label="Theme Description" />
                                </div>
                            </DialogBody>
                            <DialogFooter>
                                <div className='absolute bottom-4 right-4'>
                                    <Button type="button"  
                                            color='white'
                                            className="
                                            border
                                            border-gray-700
                                            rounded-full
                                            px-6 py-2
                                            mr-2 
                                            hover:border-1
                                            hover:border-blue-500
                                            hover:text-blue-500
                                            font-body"
                                            variant="filled"
                                        size="lg" 
                                        onClick={() => setShowThemeDialog(false)}>
                                        Cancel
                                    </Button>
                                    <Button type="submit"  
                                            disabled={!(isValid && (dirty || !themeEdit?.id))} 
                                            onClick={() => setShowThemeDialog(false)}
                                            color='white'
                                            className="
                                            border
                                            border-gray-700
                                            rounded-full
                                            px-6 py-2 
                                            hover:border-1
                                            hover:border-blue-500
                                            hover:text-blue-500
                                            font-body"
                                            variant="filled"
                                            size="lg">
                                        Save
                                    </Button>
                                </div>
                            </DialogFooter>
                        </Form>
                    )}
                </Formik>
            </Dialog>
            {(!answerThemesValidating &&
            <AnswerThemingDialog showAnswerThemingDialog={showAnswerThemingDialog} setShowAnswerThemingDialog={setShowAnswerThemingDialog}
                answerThemes={answerThemes} handleAnswerThemingSubmit={handleAnswerThemingSubmit} themesData={themesData}
                themesPage={themesPage} nextThemesPage={nextThemesPage} previousThemesPage={previousThemesPage}
                validationSchemaAnswerThemes={validationSchemaAnswerThemes}
            />
            )}
            {(!(!themeEdit && answerThemesValidating && surveyQuestionsValidating && themeAnswersValidating) &&
            <ThemeAnswersDialog showThemeAnswersDialog={showThemeAnswersDialog} setShowThemeAnswersDialog={setShowThemeAnswersDialog}
                themeEdit={themeEdit} handleThemeAnswersSubmit={handleThemeAnswersSubmit} 
                validationSchemaThemeAnswers={validationSchemaThemeAnswers} surveyRespondentAnswers={surveyRespondentAnswers}
                themeAnswersThemesState={themeAnswersThemesState} setThemeAnswersThemesState={setThemeAnswersThemesState}
                surveyQuestions={surveyQuestions} modQShown={modQShown} setModQShown={setModQShown} themeAnswers={themeAnswers}
            />
            )}
            <ProcessReportDialog processingReport={processingReport} showProcessDialog={showProcessDialog}
                setShowProcessDialog={setShowProcessDialog} surveyAbuseMutate={surveyAbuseMutate}
                surveyRespondentAnswersMutate={surveyRespondentAnswersMutate}
            />
            <ConfirmReportAdminDialog showDialog={showConfirmReportDialog} 
                setShowConfirmReportDialog={setShowConfirmReportDialog} 
                confirmingAnswer={confirmingAnswer} question={surveyRespondentAnswers?.[modQShown]}
                surveyAbuseMutate={surveyAbuseMutate}/>
            <RespondentResponseSentimentListDialog showRespondentResponseSentimentListDialog={showRespondentResponseSentimentListDialog} 
                setShowRespondentResponseSentimentListDialog={setShowRespondentResponseSentimentListDialog} 
                responseSentimentListId={responseSentimentListId} setResponseSentimentListId={setResponseSentimentListId}
                respondentsResponsesSentiment={respondentsResponsesSentiment} respondentSentimentData={respondentSentimentData}/>
            <ResponsesSentimentListDialog showResponsesSentimentListDialog={showResponsesSentimentListDialog} 
                setShowResponsesSentimentListDialog={setShowResponsesSentimentListDialog}
                responsesSentimentData={responsesSentimentData} responseSentimentIdList={responseSentimentIdList}/>
        </>
    );
}
