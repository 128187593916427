import useSWR from 'swr';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import { fetcherSimple } from 'utils/api';
import ContentWrapper from 'components/ui/content-wrapper';
import {Card, CardHeader, CardBody, CardFooter, 
    Button, Typography,
    IconButton} from '@material-tailwind/react';
import MaterialIcon from 'components/material-icon';
import profilepic from 'assets/img/uploadprofilepic.png';
import Image from 'components/image';
import { useEffect, useState } from 'react';

export default function Dashboard() {
    //AccordionControls
    // const [open, setOpen] = useState(0);     
    // const handleOpen = (value) => setOpen(open === value ? 0 : value);

    // timestamp value function with dayjs
    const [timestamp, setTimestamp] = useState(dayjs());
    const [activeListPage, setActiveListPage] = useState(1);
    const [draftListPage, setDraftListPage] = useState(1);

    const {
        data: userData,
    } = useSWR([`/v2/user`, 'GET'], ([path, method]) => fetcherSimple(path, method));

    const {
        data: activeSurveyStats,
    } = useSWR([`/v2/survey?state=active&stats=1&page=${activeListPage}`, 'GET'], ([path, method]) => fetcherSimple(path, method));

    const {
        data: draftSurveyStats,
    } = useSWR([`/v2/survey?state=draft&stats=1&page=${draftListPage}`, 'GET'], ([path, method]) => fetcherSimple(path, method));

    const {
        data: overallStats,
    } = useSWR([`/v2/survey?stats=2`, 'GET'], ([path, method]) => fetcherSimple(path, method));

    const pfpImageUrl = process.env.REACT_APP_API_SERVER + `/v2/user/picture`;

    return (
        <>
            <ContentWrapper>
                <div className='pt-6 py-2 mb-6'>
                <h1 className='md:text-5xl text-2xl font-display text-black py-2 md:px-6 px-2 rounded-xl md:rounded-3xl 
                bg-white border-b-2 lg:border-b-2 border-blue-gray-500 w-fit mx-auto'>
                    Dashboard
                </h1>
                </div>
                <div className="mb-10 p-2 text-start rounded-xl lg:p-6 p-4">
                    <Typography variant="h6" className='bg-white p-2 rounded-lg shadow'>
                        Welcome back, {userData?.name || ''}!
                    </Typography>
                    <div className='flex flex-row gap-2'>
                        <div className='basis-10/12 min-h-[100px] mt-4 pr-2'>
                            <div className='grid grid-cols-2 gap-4'>
                                <Card className='bg-white min-h-[100px] rounded-xl'>
                                    <CardHeader floated={false} className='w-full bg-blue-gray-500 text-white m-0 rounded-t-xl 
                                    rounded-b-none px-2 py-1 text-lg font-display'>
                                        <Typography>General Info</Typography>
                                    </CardHeader>
                                    <CardBody>
                                        <table className="w-full min-w-max table-auto text-left">
                                            <tbody>
                                                <tr className="even:bg-blue-gray-50/50">
                                                    <td className="px-2 py-1 font-body text-sm text-blue-gray-700">
                                                        Total Surveys
                                                    </td>
                                                    <td className="px-2 py-1 font-body text-sm text-blue-gray-700">
                                                        {overallStats?.stats.total_surveys || '0'}
                                                    </td>
                                                </tr>
                                                <tr className="even:bg-blue-gray-50/50">
                                                    <td className="px-2 py-1 font-body text-sm text-blue-gray-700">
                                                        Total Respondents
                                                    </td>
                                                    <td className="px-2 py-1 font-body text-sm text-blue-gray-700">
                                                        {overallStats?.stats.total_respondents || '0'}
                                                    </td>
                                                </tr>
                                                <tr className="even:bg-blue-gray-50/50">
                                                    <td className="px-2 py-1 font-body text-sm text-blue-gray-700">
                                                        Surveys Live
                                                    </td>
                                                    <td className="px-2 py-1 font-body text-sm text-blue-gray-700">
                                                        {overallStats?.stats.surveys_live || '0'}
                                                    </td>
                                                </tr>
                                                <tr className="even:bg-blue-gray-50/50">
                                                    <td className="px-2 py-1 font-body text-sm text-blue-gray-700">
                                                        Respondents Online
                                                    </td>
                                                    <td className="px-2 py-1 font-body text-sm text-blue-gray-700">
                                                        {overallStats?.stats.respondents_online || '0'}
                                                    </td>
                                                </tr>
                                                <tr className="even:bg-blue-gray-50/50">
                                                    <td className="px-2 py-1 font-body text-sm text-blue-gray-700">
                                                        Open Moderations (all surveys)
                                                    </td>
                                                    <td className="px-2 py-1 font-body text-sm text-blue-gray-700">
                                                        {overallStats?.stats.open_moderations || '0'}
                                                    </td>
                                                </tr>
                                                <tr className="even:bg-blue-gray-50/50">
                                                    <td className="px-2 py-1 font-body text-sm text-blue-gray-700">
                                                        Number of Themes (all Surveys)
                                                    </td>
                                                    <td className="px-2 py-1 font-body text-sm text-blue-gray-700">
                                                        {overallStats?.stats.total_themes || '0'}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </CardBody>
                                </Card>
                                <Card className='bg-white min-h-[100px] rounded-xl'>
                                    <CardHeader floated={false} className='w-full bg-blue-gray-500 text-white m-0 rounded-t-xl 
                                    rounded-b-none px-2 py-1 text-lg font-display'>
                                        <Typography>Surveys by Status</Typography>
                                    </CardHeader>
                                    <CardBody>
                                    <table className="w-full table-auto text-left border-b border-blue-gray-500">
                                        <thead>
                                            <tr>
                                                <th className="border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-sm text-blue-gray-700 rounded-t-lg">
                                                <MaterialIcon name="summarize"/>&nbsp;Survey Type
                                                </th>
                                                <th  className="border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-sm text-blue-gray-700 rounded-t-lg">
                                                <MaterialIcon name="group"/>&nbsp;Respondents
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {overallStats && overallStats.surveys && overallStats.surveys.map(survey_state => 
                                                <tr key={survey_state.state} className="even:bg-blue-gray-50/50">
                                                    <td className="px-2 py-1 font-body text-sm text-blue-gray-700 capitalize">
                                                        {survey_state.state || 'N/A'}
                                                    </td>
                                                    <td className="px-2 py-1 font-body text-sm text-blue-gray-700">
                                                        {survey_state.respondent_count || '0'}
                                                    </td>
                                                </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </CardBody>

                                </Card>
                            </div>
                            <div className='mt-4'>
                                <Card className='bg-white min-h-[100px] rounded-xl'>
                                    <CardHeader floated={false} className='w-full bg-blue-gray-500 text-white m-0 rounded-t-xl 
                                    rounded-b-none px-2 py-1 text-lg font-display'>
                                        <Typography>Active Surveys</Typography>
                                    </CardHeader>
                                    <CardBody>
                                        <table className="w-full min-w-max table-auto text-left border-b border-blue-gray-500">
                                            <thead>
                                                <tr>
                                                    <th className="border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-sm text-blue-gray-700 rounded-t-lg">
                                                    <MaterialIcon name="summarize"/>&nbsp;Survey Name
                                                    </th>
                                                    <th className="border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-sm text-blue-gray-700 rounded-t-lg text-center">
                                                        <MaterialIcon name="group"/>&nbsp;Total Respondents
                                                    </th>
                                                    <th className="border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-sm text-blue-gray-700 rounded-t-lg text-center">
                                                        <MaterialIcon name="report"/>&nbsp;Moderation Requests
                                                    </th>
                                                    <th className="border-b-2 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-sm text-blue-gray-700 rounded-t-lg text-right">
                                                        <MaterialIcon name="insights"/>&nbsp;Themes
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {activeSurveyStats && activeSurveyStats.data && activeSurveyStats.data.map(survey =>
                                                <tr key={survey.nanoid} className="even:bg-blue-gray-50/50">
                                                    <td className="px-2 py-1 font-body text-sm text-blue-gray-700">
                                                            {survey.name}
                                                    </td>
                                                    <td className="px-2 py-1 text-center font-body text-sm text-blue-gray-700">
                                                            {survey.respondents.total}
                                                    </td>
                                                    <td className="px-2 py-1 text-center font-body text-sm text-blue-gray-700">
                                                            {survey.abuse_reports.pending}
                                                    </td>
                                                    <td className="px-2 py-1 text-right font-body text-sm text-blue-gray-700">
                                                            {survey.themes}
                                                    </td>
                                                </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        {activeSurveyStats && activeSurveyStats.pager && 
                                            <div className='mt-2 flex justify-end'>
                                                <IconButton color="black" type="button" variant="filled" size="sm"
                                                    disabled={activeListPage === 1} 
                                                    onClick={() => {const previousPage = activeListPage; setActiveListPage(previousPage-1);}}>
                                                    <MaterialIcon name="arrow_back_ios_new" size="sm"/>
                                                </IconButton>
                                                <IconButton color="black" type="button" variant="filled" className='ml-2' size="sm"
                                                    disabled={activeSurveyStats.pager.current_page === activeSurveyStats.pager.last_page} 
                                                    onClick={() => {const previousPage = activeListPage; setActiveListPage(previousPage+1);}}>
                                                    <MaterialIcon name="arrow_forward_ios" size="sm"/>
                                                </IconButton>
                                            </div>
                                        }
                                    </CardBody>
                                </Card>
                            </div>
                            <div className='mt-4'>
                                <Card className='bg-white min-h-[100px] rounded-xl'>
                                    <CardHeader floated={false} className='w-full bg-blue-gray-500 text-white m-0 rounded-t-xl 
                                    rounded-b-none px-2 py-1 text-lg font-display'>
                                        <Typography>Draft Surveys</Typography>
                                    </CardHeader>
                                    <CardBody>
                                        <table className="w-full min-w-max table-auto text-left border-b border-blue-gray-500">
                                            <thead>
                                                <tr>
                                                    <th className="border-b-2 border-r-white border-r-4 border-blue-gray-500  bg-blue-gray-200 px-2 py-2 font-body text-sm text-blue-gray-700 rounded-t-lg">
                                                    <MaterialIcon name="summarize"/>&nbsp;Survey Name
                                                    </th>
                                                    <th className="border-b-2 border-r-white border-r-4 border-blue-gray-500  bg-blue-gray-200 px-2 py-2 font-body text-sm text-blue-gray-700 rounded-t-lg text-center">
                                                        <MaterialIcon name="insights"/>&nbsp;Themes
                                                    </th>
                                                    <th className="text-right border-b-2 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-sm text-blue-gray-700 rounded-t-lg">
                                                    <MaterialIcon name="calendar_month"/>&nbsp;Date Created
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {draftSurveyStats && draftSurveyStats.data && draftSurveyStats.data.map((survey =>
                                                <tr key={survey.nanoid} className="even:bg-blue-gray-50/50">
                                                    <td className="px-2 py-1 font-body text-sm text-blue-gray-700">
                                                            {survey.name}
                                                    </td>
                                                    <td className="px-2 py-1 text-center font-body text-sm text-blue-gray-700">
                                                            {survey.abuse_reports.pending}
                                                    </td>
                                                    <td className="px-2 py-1 text-right font-body text-sm text-blue-gray-700">
                                                            {dayjs(survey.create_date,'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')}
                                                    </td>
                                                </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {draftSurveyStats && draftSurveyStats.pager && 
                                            <div className='mt-2 flex justify-end'>
                                                <IconButton color="black" type="button" variant="filled" size="sm"
                                                    disabled={draftListPage === 1} 
                                                    onClick={() => {const previousPage = draftListPage; setDraftListPage(previousPage-1);}}>
                                                    <MaterialIcon name="arrow_back_ios_new" size="sm"/>
                                                </IconButton>
                                                <IconButton color="black" type="button" variant="filled" className='ml-2' size="sm"
                                                disabled={draftListPage === draftSurveyStats.pager.last_page} 
                                                onClick={() => {const previousPage = draftListPage; setDraftListPage(previousPage+1);}}>
                                                    <MaterialIcon name="arrow_forward_ios" size="sm"/>
                                                </IconButton>
                                            </div>
                                        }
                                    </CardBody>
                                </Card>
                            </div>
                            <div className='mt-4'>
                                <Card className='bg-white min-h-[100px] rounded-xl'>
                                    <CardHeader floated={false} className='w-full bg-blue-gray-500 text-white m-0 rounded-t-xl 
                                    rounded-b-none px-2 py-1 text-lg font-display'>
                                        <Typography>Account Status</Typography>
                                    </CardHeader>
                                    <CardBody>
                                        <table className="w-full min-w-max table-auto text-left">
                                            <tbody>
                                                <tr className="even:bg-blue-gray-50/50">
                                                    <td className="px-2 py-1 font-body text-sm text-blue-gray-700" color="blue-gray">
                                                        Account Type
                                                    </td>
                                                    <td className="px-2 py-1 font-body text-sm text-blue-gray-700">
                                                        [DETAILS]
                                                    </td>
                                                </tr>
                                                <tr className="even:bg-blue-gray-50/50">
                                                    <td className="px-2 py-1 font-body text-sm text-blue-gray-700" color="blue-gray">
                                                        Number of Users
                                                    </td>
                                                    <td className="px-2 py-1 font-body text-sm text-blue-gray-700">
                                                        [Used] of [Total allowed]
                                                    </td>
                                                </tr>
                                                <tr className="even:bg-blue-gray-50/50">
                                                    <td className="px-2 py-1 font-body text-sm text-blue-gray-700" color="blue-gray">
                                                        Plan Length
                                                    </td>
                                                    <td className="px-2 py-1 font-body text-sm text-blue-gray-700">
                                                        #NUM
                                                    </td>
                                                </tr>
                                                <tr className="even:bg-blue-gray-50/50">
                                                    <td className="px-2 py-1 font-body text-sm text-blue-gray-700" color="blue-gray">
                                                        Renewal Date
                                                    </td>
                                                    <td className="px-2 py-1 font-body text-sm text-blue-gray-700">
                                                        [DETAILS]
                                                    </td>
                                                </tr>
                                                <tr className="even:bg-blue-gray-50/50">
                                                    <td className="px-2 py-1 font-body text-sm text-blue-gray-700" color="blue-gray">
                                                    </td>
                                                    <td className="px-2 py-1">
                                                        <Typography className="font-body text-sm">
                                                            <Button
                                                                type="button"
                                                                variant="filled"
                                                                color="white"
                                                                size="sm"
                                                                className="my-2 rounded-full py-1 px-4 font-body font-thin text-sm 
                                                                normal-case hover:border-1 hover:border-blue-500 
                                                                hover:text-blue-500 border border-gray-700 mr-4">
                                                                Upgrade Plan
                                                            </Button>
                                                        </Typography>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                        <div className='min-h-[100px] mt-4 min-w-fit'>
                            <Card className="rounded-xl" >
                                <CardHeader floated={false} className="w-[120px] h-[120px] mx-auto rounded-full">
                                    <Image alt="Profile PictureORGANISATION —" 
                                        key={timestamp}
                                        src={pfpImageUrl}
                                        className=""
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src=`${profilepic}`;
                                        }} 
                                    />
                                </CardHeader>
                                <CardBody className="text-center">
                                    <Typography variant="h6" color="blue-gray" className="mb-2">
                                        {userData?.name || ''}
                                    </Typography>
                                </CardBody>
                                <CardFooter className="text-center pt-2 bg-blue-gray-400 rounded-b-xl">
                                    <Typography className="text-white font-body text-xs" textGradient>
                                        <MaterialIcon name="store"/> {userData?.organisation || 'N/A'}<br /><MaterialIcon name="badge"/> {userData?.job_role || 'N/A'}
                                    </Typography>
                                    <Typography className="font-body text-sm w-full">
                                        <Button
                                            type="button"
                                            variant="filled"
                                            color="white"
                                            size="sm"
                                            className="my-2 rounded-full py-1 px-4 font-body font-thin text-sm 
                                            normal-case hover:border-1 hover:border-blue-500 
                                            hover:text-blue-500 border border-gray-700 mt-4">
                                            Upgrade Plan
                                        </Button>
                                    </Typography>                                    
                                </CardFooter>
                            </Card>
                        </div>
                    </div> 
                </div>
                <div className="">
                </div>
            </ContentWrapper>
        </>
    );
}
