import React, { useState, createRef } from 'react';
import { mutate } from 'swr';
import {
    Button, Card, CardBody, CardFooter, CardHeader, Dialog, DialogHeader, DialogBody, DialogFooter, Input, 
    IconButton, Typography,
} from '@material-tailwind/react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { fetcherSimple, fetcherFull, fetcherFileUpload } from 'utils/api';
import FormikSwitch from 'components/form/formik-switch';
import MaterialIcon from 'components/material-icon';

export default function PeopleContent({ survey, surveyId, surveyRecipients, surveyRecipientsError, recipientsMutate }) {
    const inputTextRef = createRef(null);
    const inputCsvFileRef = createRef(null);

    const addRecipient = async () => {
        if (survey.state !== 'draft' && survey.state !== 'published') {
            toast.warn('Survey has already been started and can no longer be edited!');
            return;
        };
        if (!inputTextRef.current.value) {
            toast.warn('Email for Respondent missing!');
            return;
        };
        const submitToast = toast.loading('Saving Recipient...');
        const uidStr = encodeURI(inputTextRef.current.value);
        const response = await fetcherFull(`/v2/survey/${surveyId}/recipient`, 'POST', { uid: uidStr });
        if (response && response.id) {
            toast.update(submitToast, {
                render: 'Recipient Uploaded', type: 'success', isLoading: false, autoClose: 5000,
                pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true
            });
            recipientsMutate(surveyRecipients);
            inputTextRef.current.value = '';
        } else {
            toast.update(submitToast, {
                render: 'Error uploading Recipient', type: 'error', isLoading: false, autoClose: 5000,
                pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true
            });
        }
    };

    const deleteRecipient = async (recipientId) => {
        if (survey.state !== 'draft' && survey.state !== 'published') {
            toast.warn('Survey has already been started and can no longer be edited!');
            return;
        };
        const submitToast = toast.loading('Deleting Recipient...');
        const response = await fetcherSimple(`/v2/survey/${surveyId}/recipient/${recipientId}`, 'DELETE');
        if (response && response.success) {
            toast.update(submitToast, {
                render: 'Recipient Deleted', type: 'success', isLoading: false, autoClose: 5000,
                pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true
            });
            recipientsMutate(surveyRecipients);
            inputTextRef.current.value = '';
        } else {
            toast.update(submitToast, {
                render: 'Error deleting Recipient', type: 'error', isLoading: false, autoClose: 5000,
                pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true
            });
        }
    };

    const createRecipientLink = async (recipientId) => {
        if (survey.state === 'finished') {
            toast.warn('Survey is finished!');
            return;
        };
        const submitToast = toast.loading('Generating Recipient Invite URL...');
        const response = await fetcherSimple(`/v2/survey/${surveyId}/recipient/${recipientId}/invitation`, 'GET');
        if (response && response.url) {
            navigator.clipboard.writeText(response.url);
            toast.update(submitToast, {
                render: 'Link for Recipient copied to Clipboard', type: 'success', isLoading: false, autoClose: 5000,
                pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true
            });
            recipientsMutate(surveyRecipients);
            inputTextRef.current.value = '';
        } else {
            toast.update(submitToast, {
                render: 'Error creating Link', type: 'error', isLoading: false, autoClose: 5000,
                pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true
            });
        }
    };

    const createInvitationCSV = async () => {
        if (survey.state === 'finished') {
            toast.warn('Survey is finished!');
            return;
        };
        const submitToast = toast.loading('Generating Recipient Invitation CSV...');
        const response = await fetcherSimple(`/v2/survey/${surveyId}/recipient/invitation`, 'GET');
        if (response) {
            const inviteList = response;
            if (inviteList.length > 0) {
                const titleKeys = ['User ID', 'Invite Link'];
                const refinedData = [];
                refinedData.push(titleKeys);
                inviteList.forEach(item => {
                    refinedData.push([item.uid.replaceAll(',', ''), item.url.replaceAll(',', '')]);
                });
                let csvContent = '';
                refinedData.forEach(row => {
                    csvContent += row.join(',') + '\n';
                });
                const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' });
                const objUrl = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.setAttribute('href', objUrl);
                link.setAttribute('download', `${survey.name}_invitationlist_${dayjs().format('YYYYMMDD-HHmm')}.csv`);
                link.click();
            } else {
                toast.warn('No recipients to invite!');
                return;
            }
            toast.update(submitToast, {
                render: 'CSV created', type: 'success', isLoading: false, autoClose: 5000,
                pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true
            });
        } else {
            toast.update(submitToast, {
                render: 'Error creating Invitation CSV', type: 'error', isLoading: false, autoClose: 5000,
                pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true
            });
        }
    };

    const handleCsvUploadClick = () => {
        inputCsvFileRef.current?.click();
    };

    const csvUploadHandler = async (event) => {
        if (survey.state !== 'draft') {
            toast.warn('Survey has already been started and can no longer be edited!');
            return;
        };
        const csvSubmitToast = toast.loading('Saving CSV...');
        if (!event.target.files) {
            toast.error('No file uploaded!');
            return;
        }
        const selectedFile = event.target.files[0];
        let fileResponse = undefined;
        try {
            fileResponse = await fetcherFileUpload(
                `/v2/survey/${surveyId}/recipient/list`, 'POST', 'csv_file', selectedFile
            );
        } catch {
            const serverError = fileResponse || 'unknown issue in uploading CSV.';
            toast.update(csvSubmitToast, {
                render: `Error uploading CSV, ${serverError}.`, type: 'error', isLoading: false, autoClose: 5000,
                pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true
            });
            console.log(fileResponse.errors);
        }
        if (fileResponse && fileResponse?.status === 200) {
            toast.update(csvSubmitToast, {
                render: 'CSV Uploaded', type: 'success', isLoading: false, autoClose: 5000,
                pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true
            });
            mutate(`/v2/survey/${surveyId}/recipient/list`);
            recipientsMutate();
        } else {
            const serverError = fileResponse || 'unknown issue in uploading CSV.';
            toast.update(csvSubmitToast, {
                render: `Error uploading Image, ${serverError}.`, type: 'error', isLoading: false, autoClose: 5000,
                pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true
            });
            console.log(fileResponse.errors);
        }
    };

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(open => !open);

    return (
        <>
            {(surveyRecipients &&
                <>
                    <div className="md:p-2 md:w-[80%] md:mx-auto">
                        <div className="">
                            <Card
                                variant="filled"
                                color="white"
                                className='rounded-xl border border-blue-gray-700'
                                shadow>
                                <CardHeader
                                    floated={false}
                                    shadow={false}
                                    className="w-full bg-blue-gray-200 text-blue-gray-800 m-0 rounded-t-xl rounded-b-none p-2 text-xl font-display flex justify-center">
                                    Anonymous or Unknown Recipient
                                </CardHeader>
                                <CardBody className="">
                                    <Typography as="span" className="mb-2 font-body text-blue-gray-700">
                                        <FormikSwitch name="is_allow_anonymous" className='bg-blue-gray-500' color="green" /> &nbsp;Allow Anonymous Respondents
                                    </Typography>
                                    <Typography as="span" className="font-body text-blue-gray-700">
                                        <Button
                                            type="button"
                                            color="white"
                                            variant="gradient"
                                            size="lg"
                                            className="mr-4 my-2 rounded-full py-1 px-4 font-body text-sm font-thin normal-case hover:border-1 hover:border-blue-500 hover:text-blue-500 border border-gray-500 align-right"
                                            onClick={() => navigator.clipboard.writeText(window.location.origin + `/respond/${survey.nanoid}`)}>
                                            Copy URL
                                        </Button>
                                        Share the link to your survey with any recepients.
                                    </Typography>
                                </CardBody>
                                <CardFooter className="py-3 bg-blue-gray-100 rounded-b-xl border-t border-blue-gray-500">
                                    <Typography className="font-display text-sm text-blue-gray-700">Selecting this will allow anonymous respondents to also answer your survey.
                                        A single link will be generated for distribution,
                                        anyone with that link will be able to enter the survey and submit responses.</Typography>
                                </CardFooter>
                            </Card>
                        </div>
                        <div>
                            <Card
                                variant="filled"
                                color="white"
                                className='rounded-xl lg border md:my-6 my-2 border-blue-gray-700'
                                shadow>
                                <CardHeader
                                    floated={false}
                                    shadow={false}
                                    className="w-full bg-blue-gray-200 text-blue-gray-800 m-0 rounded-t-xl rounded-b-none p-2 text-xl font-display flex justify-center">
                                    Known Recipients                                </CardHeader>
                                <CardBody className=''>
                                    <div className="mb-2 flex flex-row font-body">
                                        <div className="grow">
                                            <Input
                                                inputRef={inputTextRef}
                                                icon={<MaterialIcon name="person_add" />}
                                                label={"New Recipient ID"} />
                                        </div>
                                        <div className="basis-2 pl-4">
                                            <Button
                                                size="lg"
                                                className={"rounded-full text-md py-1 px-4 font-body normal-case hover:bg-amber-500 hover:text-black bg-green-800 text-white mx-2"}
                                                onClick={() => addRecipient()}>
                                                Add
                                            </Button>
                                        </div>

                                    </div>
                                    <Typography className="font-body bg-gray-100 border border-gray-500 px-6 py-1 rounded-xl text-right">
                                        Recipient Count:<Typography as="span" className="font-body text-orange-900">&nbsp;{surveyRecipients.length}</Typography>
                                    </Typography>
                                    <div>
                                        <div className={"flex justify-end gap-2 mt-6"}>
                                            <Button
                                                onClick={handleCsvUploadClick}
                                                size="lg"
                                                className="rounded-full py-1 px-4 font-body text-sm font-thin normal-case hover:border-1 hover:border-blue-500 hover:text-blue-500 align-right border border-gray-500"
                                                color="white">
                                                Select CSV
                                            </Button>
                                            <input onChange={csvUploadHandler}
                                                ref={inputCsvFileRef}
                                                type="file"
                                                accept=".csv"
                                                style={{ display: 'none' }} />
                                            <Button
                                                onClick={createInvitationCSV}
                                                size="lg"
                                                className="rounded-full py-1 px-4 font-body text-sm font-thin normal-case hover:border-1 hover:border-blue-500 hover:text-blue-500 align-right border border-gray-500"
                                                color="white">
                                                Download Invite CSV
                                            </Button>
                                            <Button
                                                onClick={handleOpen}
                                                size="lg"
                                                className="rounded-full py-1 px-4 font-body text-sm font-thin normal-case hover:border-1 hover:border-blue-500 hover:text-blue-500 align-right border border-gray-500"
                                                color="white">
                                                View Recipients
                                            </Button>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardFooter className="py-3 bg-blue-gray-100 rounded-b-xl border-t border-blue-gray-500">
                                    <Typography className="font-display text-sm text-blue-gray-700">Uploading a CSV, or typing in a recipient ID, will generate unique links. This will allow you to track
                                        known respondents. Please note that Gobby does not save the known respondent information, rather it generates a unique ID that you can associate
                                        with your own records. This is a security feature that allows Gobby to protect recipient identity by not storing and personally
                                        identifiable information. If you need to upload a new list, add, or repeat the proces then new, unique ID, and survey links will be generated each time.
                                        The survey will associate respondent data to each unique generated link/ID (including multiple generated links).</Typography>
                                </CardFooter>
                            </Card>
                        </div>
                    </div>

                </>
            )}
            {(surveyRecipientsError &&
                <Typography>Error loading survey recipients</Typography>
            )}
            {(surveyRecipients &&
                <>
                    <Dialog open={open} handler={handleOpen} className='min-w-fit'>
                        <DialogHeader className='bg-gray-300 text-black font-display rounded-t-lg'>Recipient List</DialogHeader>
                        <DialogBody className='overflow-y-auto max-h-[80vh] font-body'>
                            There {surveyRecipients.length && surveyRecipients.length > 1 ? `are ` : `is `}
                            {surveyRecipients.length} recipient{surveyRecipients.length && surveyRecipients.length > 1 ? `s` : ``} and their details are:&nbsp;
                            <ul>
                                {surveyRecipients.map(({ id, uid }) => (
                                    <li key={uid} className="flex items-center">
                                        <div className="flex-grow">{uid}</div>
                                        <IconButton className='mb-2' color="blue-gray" type="button"
                                            variant="text" size="sm"
                                            onClick={() => createRecipientLink(id)}>
                                            <MaterialIcon name="share" size="lg" />
                                        </IconButton>
                                        <IconButton className='mb-2' color="blue-gray" type="button"
                                            variant="text" size="sm"
                                            onClick={() => deleteRecipient(id)}>
                                            <MaterialIcon name="delete" size="lg" />
                                        </IconButton>
                                    </li>
                                ))}
                            </ul>
                        </DialogBody>
                        <DialogFooter>
                            <Button variant="gradient"
                                    color="white"
                                className='rounded-full px-6 py-2 absolute bottom-4 right-4 
                                            hover:border-1
                                            hover:border-blue-500
                                            hover:text-blue-500
                                            border
                                            border-gray-500
                                            font-body'
                                size="lg"
                                onClick={handleOpen}>
                                <span>Close</span>
                            </Button>
                        </DialogFooter>
                    </Dialog>
                </>
            )}
        </>
    );
}
